import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classnames from 'classnames/bind';

import { closeSnackbar } from 'state/reducers/snackbarReducer';
import { RootState } from 'state/store';

import styles from './Snackbar.module.scss';

const cx = classnames.bind(styles);

const Snackbar = () => {
  const { snackbar } = useSelector((state: RootState) => ({
    snackbar: state.snackbar
  }));
  const dispatch = useDispatch();

  let timer: NodeJS.Timeout;

  const handleTimeout = () => {
    timer = setTimeout(() => {
      dispatch(closeSnackbar());
    }, snackbar.duration);
  };

  const showType = () => {
    if (!snackbar.type) {
      return 'is-primary';
    }

    switch (snackbar.type) {
      case 'success':
        return 'is-success';
      case 'danger':
        return 'is-danger';
      case 'info':
        return 'is-primary';
    }
  };

  useEffect(() => {
    if (snackbar.isShow) {
      handleTimeout();
    }
    return () => {
      clearTimeout(timer);
    };
  }, [snackbar.isShow]);

  return <>{snackbar.isShow && <div className={cx('notification', 'snackbar', showType())}>{snackbar.message}</div>}</>;
};

export default Snackbar;
