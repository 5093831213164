import React, { useEffect, useState } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import classnames from 'classnames/bind';
import { useDispatch, useSelector } from 'react-redux';

import { CryptoWallet } from 'types';
import { getMetaMaskInfo } from 'utils/crypto';
import Loading from 'components/Loading';
import Modal from 'components/Modal';
import ConfirmModal from 'components/ConfirmModal';

import { openSnackbar } from 'state/reducers/snackbarReducer';
import {
  getCryptoWallets,
  setConnectingValue,
  addCryptoWallet,
  deleteCryptoWallet,
  makeDefaultCryptoWallet
} from 'state/reducers/userReducer';
import { RootState } from 'state/store';

import logoBlack from 'assets/images/at-black.png';
import metamask from 'assets/images/metamask.png';

import styles from './Wallet.module.scss';

const cx = classnames.bind(styles);

const Wallet = ({ profile = true, showConnect = true }) => {
  const dispatch = useDispatch();
  const [wallet, setWallet] = useState<CryptoWallet | null>(null);
  const [showConfirm, setShowConfirm] = useState(false);
  const [isOpenConnectWallet, setIsOpenConnectWallet] = useState(false);
  const { status, cryptoWallets } = useSelector((state: RootState) => state.user);
  const isLoading = status === 'pending';

  useEffect(() => {
    if (profile) dispatch(getCryptoWallets());
  }, [profile]);

  const handleConnectWallet = async () => {
    try {
      dispatch(setConnectingValue(true));
      const { wallet } = await getMetaMaskInfo();
      dispatch(addCryptoWallet({ wallet, raiseError: true }));
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (err: any) {
      // @TODO - fix hacky solution
      const errMsg = ((err?.message || err) + '').split('[')[0].trim();

      dispatch(
        openSnackbar({
          message: errMsg,
          type: 'danger'
        })
      );
    }
  };

  const handleDeleteClick = (wallet: CryptoWallet) => {
    setWallet(wallet);
    setShowConfirm(true);
  };

  const handleDelete = () => {
    setShowConfirm(false);
    if (wallet?.guid) dispatch(deleteCryptoWallet(wallet.guid));
  };

  const handleMakeDefault = (wallet: CryptoWallet) => {
    if (wallet?.guid) dispatch(makeDefaultCryptoWallet(wallet.guid));
  };

  return (
    <>
      <div className={cx('is-size-4 flexbox justify-between', 'has-text-weight-bold')}>
        My Wallets
        {showConnect && (
          <button className={cx('button')} onClick={() => setIsOpenConnectWallet(true)}>
            Connect Wallet
          </button>
        )}
      </div>
      <div className={cx('py-4 is-justify-content-flex-start	is-flex is-flex-direction-row is-flex-wrap-wrap')}>
        {isLoading && <Loading />}
        {cryptoWallets.length === 0 && !isLoading && (
          <p className="is-size-4 has-text-grey is-align-self-center is-flex-grow-1	has-text-centered">
            There is no wallet connected.
          </p>
        )}

        {cryptoWallets.length > 0 && (
          <table className={cx('table', 'crypto-table')}>
            <thead>
              <tr>
                <th>Wallet</th>
                <th>Type</th>
                <th>Public Address</th>
                <th>Balance</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {cryptoWallets
                .filter((wallet) => (wallet.isManaged ? profile : true))
                .map((wallet) => (
                  <tr key={wallet?.guid}>
                    <th>
                      {wallet.isManaged && <img className={cx('at-logo')} src={logoBlack} alt="AT" />}
                      {!wallet.isManaged && 'Other'}
                    </th>
                    <td>{wallet.blockchainType}</td>
                    <td title={wallet.publicAddress}>
                      <div className={cx('public-address')}>{wallet.publicAddress}</div>
                    </td>
                    <th>{!wallet.isManaged ? Math.round((wallet.balance || 0) * 10000) / 10000 : ''}</th>
                    <td>
                      {profile && !wallet.isManaged && (
                        <>
                          <button
                            className="button is-success is-small mr-2"
                            disabled={wallet.isDefault}
                            onClick={() => handleMakeDefault(wallet)}>
                            Default
                          </button>

                          <button className="button is-danger is-small mr-2" onClick={() => handleDeleteClick(wallet)}>
                            Remove
                          </button>
                        </>
                      )}
                      <CopyToClipboard
                        text={wallet.publicAddress}
                        onCopy={() =>
                          dispatch(
                            openSnackbar({
                              message: 'Copied',
                              type: 'success'
                            })
                          )
                        }>
                        <button className="button is-small">Copy</button>
                      </CopyToClipboard>
                    </td>
                  </tr>
                ))}
              {profile && (
                <tr>
                  <td colSpan={5}>
                    *AT Wallet would be used for only NFT tracking in case you purchase with your credit card.
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        )}
      </div>
      {showConfirm && <ConfirmModal onConfirm={handleDelete} onClose={() => setShowConfirm(false)} />}

      {isOpenConnectWallet && (
        <Modal onClose={() => setIsOpenConnectWallet(false)}>
          <div className={cx('connect-wallet')}>
            <div className={cx('is-size-4', 'has-text-weight-bold', 'mb-4')}>Connect your wallet</div>
            <div className={cx('metamask')} onClick={handleConnectWallet}>
              <img src={metamask} />
              Metamask
            </div>
          </div>
        </Modal>
      )}
    </>
  );
};

export default Wallet;
