import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import classnames from 'classnames/bind';

import { LoginRequest } from 'types';
import { loginUser, logout } from 'state/reducers/sessionReducer';
import { RootState } from 'state/store';

import { useNavigate } from 'react-router-dom';
import styles from './Login.module.scss';

const cx = classnames.bind(styles);

export type LoginProps = {
  type: 'mod' | 'red';
  onChange: (p: 'register') => void;
};

const Login = (props: LoginProps) => {
  const navigate = useNavigate();
  const { session } = useSelector((state: RootState) => ({
    session: state.session
  }));
  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm<LoginRequest>();

  const onSubmit = (data: LoginRequest) => {
    dispatch(loginUser(data));
  };

  const queryParams = new URLSearchParams(window.location.search);
  const sessionExpired = queryParams.get('session-expired');

  useEffect(() => {
    dispatch(logout());
  }, []);

  useEffect(() => {
    if (session.authToken) {
      if (props.type === 'red') {
        const lastUrl = localStorage.getItem('lastUrl');
        if (lastUrl && lastUrl !== undefined) {
          navigate(lastUrl);
        } else {
          navigate('/');
        }
      }
    }
  }, [session.authToken]);

  return (
    <>
      <div className={cx('is-size-3', 'has-text-weight-semibold', 'mb-4')}>Login</div>

      <form onSubmit={handleSubmit(onSubmit)}>
        <div className={cx('field')}>
          <label className={cx('label')}>Username</label>
          <div className={cx('control')}>
            <input
              className={cx('input', { 'is-danger': errors.username })}
              {...register('username', { required: true })}
            />
          </div>
        </div>

        <div className={cx('field')}>
          <label className={cx('label')}>Password</label>
          <div className={cx('control')}>
            <input
              type="password"
              className={cx('input', { 'is-danger': errors.password })}
              {...register('password', { required: true })}
            />
          </div>
        </div>

        <div className="field">
          <button
            className={cx('button', 'is-fullwidth', {
              'is-loading': session.status === 'pending'
            })}>
            Log In
          </button>
        </div>

        {session.loginError && (
          <div className={cx('has-text-centered', 'has-text-danger', 'is-size-7')}>{session.loginError}</div>
        )}
        {sessionExpired === 'true' && (
          <div className={cx('has-text-centered', 'has-text-danger', 'is-size-7')}>
            Your session has expired, please login again.
          </div>
        )}

        <div className={cx('mt-6')}>
          <div className={cx('flexbox')}>
            <div>
              <div className={cx('is-link')}>Forgot password?</div>
            </div>
            <div className={cx('ml-auto')}>
              <div className={cx('is-link')} onClick={() => props.onChange('register')}>
                Don't have an account? Register here.
              </div>
            </div>
          </div>
        </div>
      </form>
    </>
  );
};

export default Login;
