import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import classnames from 'classnames/bind';

import { fetchCollections, setCollection } from 'state/reducers/postReducer';
import { RootState } from 'state/store';

import CollectionCard from 'components/CollectionCard';
import Skeleton from 'components/Skeleton';

import styles from './Collections.module.scss';

const cx = classnames.bind(styles);

const Collections = () => {
  const { post } = useSelector((state: RootState) => ({
    post: state.post
  }));
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (post.collections === undefined) {
      dispatch(fetchCollections());
    }
  }, []);
  return (
    <>
      <div className={cx('header')}>
        <div className={cx('container')}>
          <div className={cx('has-text-centered')}>
            <div className={cx('collaborator', 'is-size-2', 'has-text-weight-bold')}>Our Collections</div>
            <div className={cx('description', 'mt-4')}>
              We are working with some of the biggest names in the entertainment and sports industry.
            </div>
          </div>
        </div>
      </div>
      <div className={cx('container', 'mt-4')}>
        <div className={cx('trance-wrapper')}>
          <div className={cx('trance')}>
            {post.collections ? (
              <>
                {post.collections.map((collection) => (
                  <div
                    key={collection.guid}
                    className={cx('trance-card', 'clear')}
                    onClick={() => {
                      dispatch(setCollection(collection));
                      navigate(`/collection/${collection.guid}`);
                    }}>
                    <CollectionCard
                      type={1}
                      collection={collection}
                      collectionBanner={collection.thumbnailImageUrl || ''}
                      pieces={collection.pieces || 0}
                    />
                  </div>
                ))}
              </>
            ) : (
              <>
                <Skeleton className={cx('collection-skeleton')} type="rect" height="422px" width="calc(25% - 1rem)" />
                <Skeleton className={cx('collection-skeleton')} type="rect" height="422px" width="calc(25% - 1rem)" />
                <Skeleton className={cx('collection-skeleton')} type="rect" height="422px" width="calc(25% - 1rem)" />
                <Skeleton className={cx('collection-skeleton')} type="rect" height="422px" width="calc(25% - 1rem)" />
                <Skeleton className={cx('collection-skeleton')} type="rect" height="422px" width="calc(25% - 1rem)" />
                <Skeleton className={cx('collection-skeleton')} type="rect" height="422px" width="calc(25% - 1rem)" />
                <Skeleton className={cx('collection-skeleton')} type="rect" height="422px" width="calc(25% - 1rem)" />
                <Skeleton className={cx('collection-skeleton')} type="rect" height="422px" width="calc(25% - 1rem)" />
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Collections;
