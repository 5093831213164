import React from 'react';
import classnames from 'classnames/bind';

import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { setCollection, setMerchantCollection } from 'state/reducers/postReducer';
import { RootState } from 'state/store';
import { clearMerchant } from 'state/reducers/merchantReducer';
import Skeleton from 'components/Skeleton';
import styles from './FeaturedSection.module.scss';

const cx = classnames.bind(styles);

const FeaturedSection = () => {
  const { post, platform, merchant } = useSelector((state: RootState) => ({
    post: state.post,
    platform: state.platform,
    merchant: state.merchant
  }));
  const dispatch = useDispatch();

  const navigate = useNavigate();
  return post.featuredCollection && platform.platform?.featuredSection === 'Post' ? (
    <div className={cx('banner')}>
      <div className={cx('stub')}>
        <div className={cx('has-text-weight-semibold')}>FEATURED COLLECTION</div>
        <div className={cx('is-size-3', 'has-text-weight-bold', 'mb-4')}>{post.featuredCollection.name}</div>
        <div>{post.featuredCollection.description}</div>
        <div>
          <button
            className={cx('button', 'mt-5')}
            onClick={() => {
              dispatch(setCollection(post.featuredCollection));
              navigate(`/collection/${post.featuredCollection?.guid}`);
            }}>
            Explore Collection
          </button>
        </div>
      </div>
      <div className={cx('preview')}>
        <img src={post.featuredCollection?.featuredImageUrl} />
      </div>
    </div>
  ) : merchant.featuredMerchants && platform.platform?.featuredSection === 'Merchant' ? (
    <>
      <div className={cx('banner')}>
        <div className={cx('stub')}>
          <div className={cx('has-text-weight-semibold')}>FEATURED Collaborator</div>
          <div className={cx('is-size-3', 'has-text-weight-bold', 'mb-4')}>{merchant.featuredMerchants.name}</div>
          <div>{merchant.featuredMerchants.description}</div>
          <div>
            <button
              className={cx('button', 'mt-5')}
              onClick={() => {
                dispatch(clearMerchant());
                dispatch(setMerchantCollection(undefined));
                navigate(`/collaborator/${merchant.featuredMerchants?.guid}`);
              }}>
              Explore Collaborator
            </button>
          </div>
        </div>
        <div className={cx('preview')}>
          <img src={merchant.featuredMerchants?.profileImageUrl} />
        </div>
      </div>
    </>
  ) : (
    <Skeleton type="rect" width="100%" height="500px" />
  );
};

export default FeaturedSection;
