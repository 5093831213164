import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import classnames from 'classnames/bind';

import { clearMerchant, fetchMerchants } from 'state/reducers/merchantReducer';
import { RootState } from 'state/store';
import { colorGenerator } from 'utils';

import defaultAvatar from 'assets/images/default-avatar.png';

import Skeleton from 'components/Skeleton';
import { setMerchantCollection } from 'state/reducers/postReducer';
import styles from './Collaborators.module.scss';

const cx = classnames.bind(styles);

const Collaborators = () => {
  const { merchant } = useSelector((state: RootState) => ({
    merchant: state.merchant
  }));
  const dispatch = useDispatch();

  const navigate = useNavigate();

  useEffect(() => {
    dispatch(fetchMerchants());
  }, []);

  return (
    <>
      <div className={cx('header')}>
        <div className={cx('container')}>
          <div className={cx('has-text-centered')}>
            <div className={cx('collaborator', 'is-size-2', 'has-text-weight-bold')}>Our Collaborators</div>
            <div className={cx('description', 'mt-4')}>
              We are working with some of the biggest names in the entertainment and sports industry.
            </div>
          </div>
        </div>
      </div>
      <div className={cx('container', 'mt-4')}>
        <div className={cx('trance-wrapper')}>
          <div className={cx('trance', 'collaborators')}>
            {merchant.merchants ? (
              <>
                {merchant.merchants.map((m) =>
                  m.nftCollectionPostGroupCount && m.nftCollectionPostGroupCount > 0 ? (
                    <div
                      key={m.guid}
                      className={cx('trance-card')}
                      onClick={() => {
                        dispatch(clearMerchant());
                        dispatch(setMerchantCollection(undefined));
                        navigate(`/collaborator/${m.guid}`);
                      }}>
                      <div className={cx('top')}>
                        <div className={cx('preview')}>
                          <div className={cx('foreground')}>
                            <object
                              className={cx('thumbnail')}
                              data={m.profileImageUrl || defaultAvatar}
                              style={{ backgroundColor: colorGenerator(m.userDisplayName) }}
                            />
                            <div className={cx('is-size-5', 'has-text-weight-semibold', 'mt-2')}>
                              {m.userDisplayName}
                            </div>
                          </div>
                          <img className={cx('background')} src={m.backgroundImageUrl || defaultAvatar} />
                        </div>
                      </div>
                      <div className={cx('bottom')}>
                        <div className={cx('has-text-left', 'width-thirds')}>
                          <div className={cx('is-size-7')}>Collections</div>
                          <div className={cx('has-text-weight-bold', 'is-size-6')}>{m.nftCollectionPostGroupCount}</div>
                        </div>
                        <div className={cx('has-text-centered', 'width-thirds')}>
                          <div className={cx('is-size-7')}>Items</div>
                          <div className={cx('has-text-weight-bold', 'is-size-6')}>4K</div>
                        </div>
                        <div className={cx('has-text-right', 'width-thirds')}>
                          <div className={cx('is-size-7')}>Owners</div>
                          <div className={cx('has-text-weight-bold', 'is-size-6')}>3.4K</div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    ''
                  )
                )}
              </>
            ) : (
              <>
                <Skeleton className={cx('collection-skeleton')} type="rect" height="348px" width="calc(25% - 1rem)" />
                <Skeleton className={cx('collection-skeleton')} type="rect" height="348px" width="calc(25% - 1rem)" />
                <Skeleton className={cx('collection-skeleton')} type="rect" height="348px" width="calc(25% - 1rem)" />
                <Skeleton className={cx('collection-skeleton')} type="rect" height="348px" width="calc(25% - 1rem)" />
                <Skeleton className={cx('collection-skeleton')} type="rect" height="348px" width="calc(25% - 1rem)" />
                <Skeleton className={cx('collection-skeleton')} type="rect" height="348px" width="calc(25% - 1rem)" />
                <Skeleton className={cx('collection-skeleton')} type="rect" height="348px" width="calc(25% - 1rem)" />
                <Skeleton className={cx('collection-skeleton')} type="rect" height="348px" width="calc(25% - 1rem)" />
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Collaborators;
