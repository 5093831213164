import React, { useState } from 'react';
import { Post } from 'types';
import classnames from 'classnames/bind';
import playIcon from 'assets/images/icons/play-solid.svg';
import closeIcon from 'assets/images/icons/xmark-solid.svg';
import Modal from 'components/Modal';
import Zoom from 'components/Zoom';
import styles from './ItemCard.module.scss';
import 'react-medium-image-zoom/dist/styles.css';

export type ItemCardProps = {
  item: Post;
};

const cx = classnames.bind(styles);

const ItemCard: React.FC<ItemCardProps> = ({ item }) => {
  const [isShowVideoModal, setIsShowVideoModal] = useState(false);
  const [isShowModal, setIsShowModal] = useState(false);

  return (
    <>
      <div className={cx('collection-card')}>
        {item?.postType === 'Image' ? (
          <img className={cx('thumbnail')} src={item.contentUrl} onClick={() => setIsShowModal(true)} />
        ) : item?.postType === 'Video' ? (
          <div className={cx('thumbnail', 'thumbnail-video')} onClick={() => setIsShowVideoModal(true)}>
            <video src={item.contentUrl} />
            <div className={cx('play-video')}>
              <img src={playIcon} />
            </div>
          </div>
        ) : (
          item?.postType === 'Audio' && (
            <div className={cx('thumbnail-audio')}>
              <img className={cx('preview-audio')} src={item.previewContentUrl} />
              <audio className={cx('play-audio')} src={item.contentUrl} controls />
            </div>
          )
        )}
      </div>
      {isShowModal && (
        <Modal className="image-viewer" backgroundColor="black" onClose={() => setIsShowModal(false)}>
          <div className={cx('close-modal')} onClick={() => setIsShowModal(false)}>
            <img src={closeIcon} />
          </div>
          <div className={cx('zoom')}>
            {(item?.thumbnailImageUrl || item?.contentUrl) && (
              <Zoom src={item?.contentUrl || item?.thumbnailImageUrl} />
            )}
            {/* <Zoom>
              <img src={item?.thumbnailImageUrl} />
            </Zoom> */}
          </div>
        </Modal>
      )}
      {isShowVideoModal && (
        <div className={cx('fullscreen-video')}>
          <div className={cx('close-modal')} onClick={() => setIsShowVideoModal(false)}>
            <img src={closeIcon} />
          </div>
          <video src={item?.contentUrl} autoPlay controls controlsList="nodownload" loop />
        </div>
      )}
    </>
  );
};

export default ItemCard;
