import React from 'react';
import classnames from 'classnames/bind';
import { usePalette } from 'color-thief-react';

import { Post } from 'types';
import { colorGenerator } from 'utils';

import defaultAvatar from 'assets/images/default-avatar.png';

import styles from './CollectionCard.module.scss';

const cx = classnames.bind(styles);

interface CollectionCardProps {
  collection?: Post;
  collectionBanner?: string;
  pieces: number;
  sales?: number;
  views?: string;
  type: number;
}

const CollectionCard = (props: CollectionCardProps) => {
  const googleImgProxyUrl =
    'https://images1-focus-opensocial.googleusercontent.com/gadgets/proxy?container=focus&refresh=2592000&url=';
  const { data } = usePalette(googleImgProxyUrl + props.collectionBanner, 4, 'hex', {
    crossOrigin: 'anonymous'
  });

  return (
    <div className={cx('collection-card')}>
      <div
        className={cx('background')}
        style={{
          backgroundImage: `linear-gradient(.4turn, ${data?.[0]}, ${data?.[1]})`
        }}
      />
      <div className={cx('preview')}>
        <img className={cx('main')} src={props.collectionBanner} />
      </div>
      <div className={cx('details')}>
        {props.type === 1 && (
          <>
            <div className={cx('flexbox', 'flex-column')}>
              <div className={cx('name')}>{props.collection?.name}</div>
              <div className={cx('flexbox')}>
                <object
                  className={cx('avatar')}
                  data={props.collection?.profileImageUrl || defaultAvatar}
                  style={{ backgroundColor: colorGenerator(props.collection?.userUsername || '') }}
                />
                <div className={cx('has-text-weight-semibold', 'ml-2')}>
                  {props.collection?.merchantName || props.collection?.userUsername}
                </div>
              </div>
            </div>
            <div className={cx('description', 'mt-2')}>{props.collection?.description}</div>
          </>
        )}
        {props.type === 2 && (
          <>
            <div className={cx('name')}>{props.collection?.name}</div>
            <div className={cx('flexbox', 'mt-2')}>
              <div className={cx('mr-auto', 'has-text-left')}>
                <div className={cx('is-size-7')}>Items</div>
                <div className={cx('has-text-weight-bold', 'is-size-6')}>{props.pieces}</div>
              </div>
              {/* <div className={cx('has-text-centered')}>
                <div className={cx('has-text-weight-bold', 'is-size-6')}>{props.sales} ETH</div>
                <div className={cx('is-size-7')}>Total Bids</div>
              </div> */}
              <div className={cx('ml-auto', 'has-text-right')}>
                <div className={cx('is-size-7')}>Views</div>
                <div className={cx('has-text-weight-bold', 'is-size-6')}>{props.views}</div>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default CollectionCard;
