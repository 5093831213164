import React, { useState, useEffect, SyntheticEvent } from 'react';
import classnames from 'classnames/bind';
import zoomPlus from 'assets/images/magnifying-glass-plus-solid.png';
import zoomMinus from 'assets/images/magnifying-glass-minus-solid.png';

import interact from 'interactjs';
import styles from './Zoom.module.scss';

const cx = classnames.bind(styles);

interface ZoomProps {
  src: string;
  backgroundPosition?: string;
}

const Zoom = (props: ZoomProps) => {
  const [zoomMultiplier, setZoomMultiplier] = useState<number>(2);
  const [modalWidth, setModalWidth] = useState<number>(0);
  const [imageWidth, setImageWidth] = useState<number>(0);
  const [imageHeight, setImageHeight] = useState<number>(0);
  const [origHeight, SetOrigHeight] = useState<number>(0);

  const [canvas, setCanvas] = useState<string>('portrait');

  const updateSize = () => {
    const image = document.getElementById('view-image')?.getBoundingClientRect();
    const modal = document.getElementById('image-container')?.parentElement?.getBoundingClientRect();
    setCanvas(image && image?.width > image?.height ? 'landscape' : 'portrait');

    modal && setModalWidth(modal?.width);
    image && setImageWidth(image?.width);

    const height = origHeight * (zoomMultiplier / 2);
    setImageHeight(height);
  };
  const incZoom = () => {
    if (zoomMultiplier >= 2) {
      setZoomMultiplier(zoomMultiplier + 1);
    }
  };

  const decZoom = () => {
    if (zoomMultiplier >= 3) {
      setZoomMultiplier(zoomMultiplier - 1);
    }
  };

  const updateImageHeight = (event: SyntheticEvent) => {
    const e = event.target as HTMLImageElement;
    setImageHeight(e.height);
    SetOrigHeight(e.height);
  };

  const initializeInteract = () => {
    const position = { x: 0, y: 0 };
    interact('.view-image').draggable({
      enabled: zoomMultiplier > 2 ? true : false,
      listeners: {
        move(event) {
          position.x += event.dx;
          position.y += event.dy;
          setImageWidth(event.target.width * (zoomMultiplier / 2));
          if (zoomMultiplier <= 2) {
            event.target.style.transform = '';
          } else {
            event.target.style.transform = `translate(${position.x}px, ${position.y}px) scale(${zoomMultiplier / 2})`;
          }
        }
      },
      inertia: true,
      modifiers: [
        interact.modifiers.restrictRect({
          restriction: 'parent',
          endOnly: true
        })
      ]
    });
  };

  useEffect(() => {
    initializeInteract();
    if (zoomMultiplier >= 2) {
      updateSize();
    }
  }, [zoomMultiplier]);

  return (
    <>
      <div
        id="image-container"
        className={cx('image-zoom', canvas, imageWidth > modalWidth || zoomMultiplier > 2 ? 'full' : '')}
        style={{ height: imageHeight !== 0 ? imageHeight : 'auto' }}>
        <img
          onLoad={(event) => {
            updateSize();
            updateImageHeight(event);
          }}
          id="view-image"
          className="view-image"
          src={props.src}
          style={{ transform: `scale(${zoomMultiplier / 2})` }}
        />
        <div className={cx('controls', 'flexbox')}>
          <button
            className={cx('zoom-button', zoomMultiplier >= 10 ? 'disabled' : '')}
            type="button"
            onClick={incZoom}
            disabled={zoomMultiplier >= 10 ? true : false}>
            <img src={zoomPlus} />
          </button>
          <button
            className={cx('zoom-button', zoomMultiplier <= 2 ? 'disabled' : '')}
            type="button"
            onClick={decZoom}
            disabled={zoomMultiplier < 2 ? true : false}>
            <img src={zoomMinus} />
          </button>
        </div>
      </div>
    </>
  );
};

export default Zoom;
