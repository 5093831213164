import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm, SubmitHandler } from 'react-hook-form';
import classnames from 'classnames/bind';

import { RegisterRequest } from 'types';
import { RootState } from 'state/store';
import { registerUser } from 'state/reducers/sessionReducer';

import styles from './Register.module.scss';

const cx = classnames.bind(styles);

export type RegisterProps = {
  type: 'mod' | 'red';
  onChange: (p: 'login') => void;
};

const Register = (props: RegisterProps) => {
  const { session } = useSelector((state: RootState) => ({
    session: state.session
  }));
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm<RegisterRequest>();
  const dispatch = useDispatch();

  const onSubmit: SubmitHandler<RegisterRequest> = (data) => {
    dispatch(registerUser(data));
  };

  return (
    <>
      {session.isRegisterSuccess ? (
        <div className={cx('has-text-centered')}>
          <div className={cx('is-size-4', 'has-text-success', 'mb-4')}>Registration Successful</div>
          <div>
            You have successfully registered. Please check your inbox to verify your email address. Once your email
            address is verified, you can log in.
          </div>
        </div>
      ) : (
        <form onSubmit={handleSubmit(onSubmit)}>
          <h4 className="title is-3">Sign Up</h4>
          <div className={cx('field')}>
            <label className={cx('label')}>Username: (what you log in with)</label>
            <div className={cx('control')}>
              <input
                className={cx('input', { 'is-danger': errors.username })}
                {...register('username', { required: true })}
              />
            </div>
          </div>
          <div className={cx('field')}>
            <label className={cx('label')}>Phone Number:</label>
            <div className={cx('control')}>
              <input
                className={cx('input', { 'is-danger': errors.phoneNumber })}
                {...register('phoneNumber', {
                  required: true
                })}
                type="tel"
              />
            </div>
          </div>
          <div className={cx('field')}>
            <label className={cx('label')}>Email Address:</label>
            <div className={cx('control')}>
              <input
                className={cx('input', { 'is-danger': errors.emailAddress })}
                type="email"
                {...register('emailAddress', { required: true })}
              />
            </div>
          </div>
          <div className={cx('field')}>
            <label className={cx('label')}>Display Name: (what people see)</label>
            <div className={cx('control')}>
              <input
                className={cx('input', { 'is-danger': errors.displayName })}
                {...register('displayName', { required: true })}
              />
            </div>
          </div>
          <div className={cx('field')}>
            <label className={cx('label')}>Password:</label>
            <div className={cx('control')}>
              <input
                className={cx('input', { 'is-danger': errors.password })}
                type="password"
                {...register('password', {
                  required: true,
                  pattern: {
                    value: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/,
                    message: 'Invalid password combination'
                  }
                })}
              />
            </div>
            <div className={cx('is-size-7', 'has-text-muted', 'mt-1', { 'has-text-danger': errors.password })}>
              Password must be at least 8 characters and contain at least 1 uppercase and lowercase letter, and 1
              number.
            </div>
          </div>
          <div className="field">
            <button
              type="submit"
              className={cx('button', 'is-fullwidth', {
                'is-loading': session.status === 'pending'
              })}>
              Sign Up
            </button>
          </div>
          {session.registerError && (
            <div className={cx('has-text-centered', 'has-text-danger', 'is-size-7', 'mb-2')}>
              {session.registerError}
            </div>
          )}
          <div className={cx('has-text-muted')}>
            By signing up, you agree to the <span className={cx('is-link')}>Terms and Conditions</span> and{' '}
            <span className={cx('is-link')}>Privacy Policy</span> and to receive periodic updates.
          </div>
          <div className={cx('is-link', 'has-text-right', 'mt-6')} onClick={() => props.onChange('login')}>
            Already have an account? Sign in here.
          </div>
        </form>
      )}
    </>
  );
};

export default Register;
