import React from 'react';
import classnames from 'classnames/bind';
import { colorGenerator } from 'utils';
import { Bid, Post } from 'types';
import defaultAvatar from 'assets/images/default-avatar.png';
import { getPrice } from 'utils/post';
import styles from './PurchaseInfo.module.scss';

const cx = classnames.bind(styles);

export interface PurchaseInfoProps {
  item: Post;
  collection?: Post;
  bid?: Bid;
}

const PurchaseInfo = ({ item, collection, bid }: PurchaseInfoProps) => {
  const getBidPrice = () => {
    if (bid?.bidCounterUSDAmount) {
      return `$${bid?.bidCounterUSDAmount}`;
    } else {
      return `$${bid?.usdAmount}`;
    }
  };

  return (
    <div className={cx('purchase-information')}>
      <div className="is-size-4 has-text-weight-bold mb-4">Purchase Information</div>
      <div className="flexbox align-start mb-6">
        <img className={cx('thumbnail')} src={item?.contentUrl} />
        <div className={cx('ml-4')}>
          <div>
            <div className="serif">
              <div className="is-size-3 has-text-weight-bold">{item?.name}</div>
            </div>

            <div>
              <div className="flexbox flex">
                <object
                  className={cx('avatar', 'collaborator-avatar', 'mx-1')}
                  data={item?.marketplaceListingSellerUserProfileImageUrl || defaultAvatar}
                  style={{ backgroundColor: colorGenerator(item?.userUsername || '') }}
                />
                <span>{collection?.merchantName}</span>
              </div>
            </div>

            <div className={cx('my-4')}>{item?.description}</div>

            <div>{item?.marketplaceListingPriceType === 'BestOffer' ? 'Current Bid Price' : 'Purchase Price'}:</div>
            <div className={cx('is-size-5', 'has-text-weight-bold')}>
              {item?.marketplaceListingPriceType === 'BestOffer' ? getBidPrice() : getPrice(item)}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PurchaseInfo;
