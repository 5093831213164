import React from 'react';
import classnames from 'classnames/bind';

import { PaymentProfile } from 'types';

import visa from 'assets/images/credit-brands/cc-visa-brands.svg';
import mastercard from 'assets/images/credit-brands/cc-mastercard-brands.svg';
import amex from 'assets/images/credit-brands/cc-amex-brands.svg';

import styles from 'components/CreditCardList/CreditCardList.module.scss';

const cx = classnames.bind(styles);

export type CreditCardProp = {
  card: PaymentProfile;
  isSelected?: boolean;
  onSelect?: (p: PaymentProfile) => void;
  size?: 'sm';
};

const CreditCardList = (props: CreditCardProp) => {
  const formatCardEnding = (cardNumber: string) => {
    return cardNumber.substring(4, 8);
  };

  const showCreditCardBrand = () => {
    switch (props.card.creditCardType.toLowerCase()) {
      case 'visa':
        return visa;
      case 'mastercard':
        return mastercard;
      case 'amex':
      case 'americanexpress':
        return amex;
      default:
        return undefined;
    }
  };

  return (
    <>
      <div
        className={cx('card', `is-${props.card.creditCardType.toLowerCase()}`, {
          selected: props.isSelected,
          sm: props.size === 'sm'
        })}
        onClick={() => {
          props.onSelect ? props.onSelect(props.card) : null;
        }}>
        <div className={cx('detail')}>
          {props.card.isDefault && <div className={cx('primary')}>Primary Card</div>}
          <div className={cx('flexbox', 'width-full', 'mt-auto', 'is-size-7')}>
            <div>Ending {formatCardEnding(props.card.maskedNumber)}</div>
            <img className={cx('brand')} src={showCreditCardBrand()} />
          </div>
        </div>
        {props.card.isDefault && !props.isSelected && <small>Default Payment Card</small>}
      </div>
      {/*<div className={cx('actions')}>
        <button className="button is-success is-small mr-2" disabled={card.isDefault} onClick={onDefault}>
          Default Card
        </button>
        <button className="button is-danger is-small" onClick={onDelete}>
          Delete
        </button>
      </div>*/}
    </>
  );
};

export default CreditCardList;
