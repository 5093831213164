import { httpClient } from 'http/httpClient';
import moment from 'moment';
import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from 'state/store';
import classnames from 'classnames/bind';
import Modal from 'components/Modal';
import Switch from 'react-switch';
import { ethToUsd, usdToEth } from 'utils/rate';
import { openSnackbar } from 'state/reducers/snackbarReducer';
import { Post } from 'types';
import Loading from 'components/Loading';
import styles from './SellModal.module.scss';

const cx = classnames.bind(styles);

export interface SellModalProp {
  show: boolean;
  purchasedItem?: Post;
  onClose: () => void;
  reloadItems: () => void;
}

export interface MarketlistItemData {
  fixedPrice?: number;
  reserve?: number;
  startingBid?: number;
  buy?: number;
  days?: number;
}

const SellModal = (props: SellModalProp) => {
  const dispatch = useDispatch();
  const { show, onClose, purchasedItem, reloadItems } = props;
  const currentUserGuid = useSelector((state: RootState) => state.user.user?.guid);
  const [loading, setLoading] = useState(false);
  const [isUSD, setIsUSD] = useState(true);
  const [isFixedPrice, setIsFixedPrice] = useState(true);
  const [itemData, setItemData] = useState<MarketlistItemData>({});

  const amountUnit = isUSD ? 'USD' : 'ETH';
  const convertedUnit = isUSD ? 'ETH' : 'USD';
  const getConvertedValue = (value: undefined | number) => {
    if (!value) return '';
    return `(${isUSD ? usdToEth(value) : ethToUsd(value)}${convertedUnit})`;
  };

  const handleItemDataChange = (fieldName: keyof MarketlistItemData) => (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value) {
      setItemData({ ...itemData, [fieldName]: parseFloat(e.target.value) });
    } else {
      setItemData({ ...itemData, [fieldName]: undefined });
    }
  };

  const canSubmit =
    (isFixedPrice && itemData.fixedPrice) ||
    (!isFixedPrice && itemData.buy && itemData.days && itemData.reserve && itemData.startingBid);

  const handleSubmit = async () => {
    const payload = {
      postGUID: purchasedItem?.marketplaceListingGUID,
      status: 'Active',
      priceType: isFixedPrice ? 'FixedPrice' : undefined,
      tokenProfileKey: isUSD ? 'usd' : 'eth',
      preferredCurrency: isUSD ? 'usd' : 'eth',
      postGuid: purchasedItem?.guid,
      usdAmount: isUSD ? (isFixedPrice ? itemData.fixedPrice : itemData.startingBid) : undefined,
      ethAmount: isUSD ? undefined : isFixedPrice ? itemData.fixedPrice : itemData.startingBid,
      sellerUserGUID: currentUserGuid,
      expirationDate: isFixedPrice ? undefined : ''
    };
    if (!isFixedPrice) {
      payload.expirationDate = moment().add({ days: itemData.days }).format();
    }
    try {
      setLoading(true);
      await httpClient.post(`/users/current/marketplacelistings`, payload);
      await reloadItems();
      dispatch(
        openSnackbar({
          message: 'Successfully listed for sale.',
          type: 'success'
        })
      );
      onClose();
    } catch (e) {
      dispatch(
        openSnackbar({
          message: e + '',
          type: 'danger'
        })
      );
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      {show && (
        <Modal onClose={onClose}>
          {loading && <Loading />}
          <div className={cx('sell-modal')}>
            <h3 className="is-size-3 has-text-centered mb-4">Sell {purchasedItem?.name}</h3>
            <div className={cx('sell-modal-item')}>
              <label className={cx('sell-modal-item-label')}>List as:</label>
              <div className={cx('sell-modal-item-content')}>
                <span>ETH</span>
                <Switch
                  offColor="#3c3c3d"
                  checkedIcon={false}
                  uncheckedIcon={false}
                  checked={isUSD}
                  onChange={(checked) => setIsUSD(checked)}
                />
                <span>USD</span>
              </div>
            </div>

            <div className={cx('sell-modal-item')}>
              <label className={cx('sell-modal-item-label')}>Sell as:</label>
              <div className={cx('sell-modal-item-content')}>
                <span>Auction</span>
                <Switch
                  offColor="#3c3c3d"
                  checkedIcon={false}
                  uncheckedIcon={false}
                  checked={isFixedPrice}
                  onChange={(checked) => setIsFixedPrice(checked)}
                />
                <span>Fixed price</span>
              </div>
            </div>

            {isFixedPrice && (
              <div className={cx('sell-modal-item')}>
                <label className={cx('sell-modal-item-label')}>Price ({amountUnit}):</label>
                <div className={cx('sell-modal-item-content')}>
                  <input
                    className={cx('input')}
                    value={itemData.fixedPrice}
                    onChange={handleItemDataChange('fixedPrice')}
                    type="number"
                  />
                  <span>{getConvertedValue(itemData.fixedPrice)}</span>
                </div>
              </div>
            )}

            {!isFixedPrice && (
              <>
                <div className={cx('sell-modal-item')}>
                  <label className={cx('sell-modal-item-label')}>Starting Bid ({amountUnit}):</label>
                  <div className={cx('sell-modal-item-content')}>
                    <input
                      className={cx('input')}
                      value={itemData.startingBid}
                      onChange={handleItemDataChange('startingBid')}
                      type="number"
                    />
                    <span>{getConvertedValue(itemData.startingBid)}</span>
                  </div>
                </div>

                <div className={cx('sell-modal-item')}>
                  <label className={cx('sell-modal-item-label')}>Reserve ({amountUnit}):</label>
                  <div className={cx('sell-modal-item-content')}>
                    <input
                      className={cx('input')}
                      value={itemData.reserve}
                      onChange={handleItemDataChange('reserve')}
                      type="number"
                    />
                    <span>{getConvertedValue(itemData.reserve)}</span>
                  </div>
                </div>

                <div className={cx('sell-modal-item')}>
                  <label className={cx('sell-modal-item-label')}>Buy it now ({amountUnit}):</label>
                  <div className={cx('sell-modal-item-content')}>
                    <input
                      className={cx('input')}
                      value={itemData.buy}
                      onChange={handleItemDataChange('buy')}
                      type="number"
                    />
                    <span>{getConvertedValue(itemData.buy)}</span>
                  </div>
                </div>

                <div className={cx('sell-modal-item')}>
                  <label className={cx('sell-modal-item-label')}>Days:</label>
                  <div className={cx('sell-modal-item-content')}>
                    <input
                      className={cx('input')}
                      value={itemData.days}
                      onChange={handleItemDataChange('days')}
                      type="number"
                    />
                  </div>
                </div>
              </>
            )}

            <button className="button is-primary" disabled={!canSubmit || loading} onClick={handleSubmit}>
              Sell
            </button>
          </div>
        </Modal>
      )}
    </>
  );
};

export default SellModal;
