import { Post } from 'types';

export const getPrice = (post: Post): string => {
  if (post.marketplaceListingPreferredCurrency.toLowerCase() === 'usd') {
    return `$${post.marketplaceListingUSDAmount}`;
  }

  if (!post?.marketplaceListingGUID && !post?.lastSoldMarketplaceListingGUID) return '';

  if (post?.marketplaceListingETHAmount) {
    return `${post.marketplaceListingETHAmount.toFixed(3)} ETH`;
  }

  if (post?.marketplaceListingUSDAmount) {
    return `$${post.marketplaceListingUSDAmount}`;
  }

  if (post?.lastSoldMarketplaceListingETHAmount) {
    return `${post.lastSoldMarketplaceListingETHAmount.toFixed(3)} ETH`;
  }

  if (post?.lastSoldMarketplaceListingUSDAmount) {
    return `$${post.lastSoldMarketplaceListingUSDAmount}`;
  }

  return '';
};

export const getNftUrl = (post: Post): string | undefined => {
  if (post?.nftImageIpfsUrl) return post.nftImageIpfsUrl;
  if (post?.nftImageIpfsHash) return `https://ipfs.io/ipfs/${post.nftImageIpfsHash}`;
};
