import React, { FormEvent, useState } from 'react';
import classnames from 'classnames/bind';
import { useNavigate } from 'react-router-dom';

import { serializeFormData } from 'utils/serializeFormData';

import logo from 'assets/images/crest.png';

import styles from './Landing.module.scss';

const cn = classnames.bind(styles);

function Home() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loginError, setLoginError] = useState<string | undefined>(undefined);
  const navigate = useNavigate();

  const onLogin = (event: FormEvent) => {
    event.preventDefault();
    const user = serializeFormData(new FormData(event.target as HTMLFormElement));

    if (user.password === 'flyhigh') {
      navigate('/home');
    } else {
      setLoginError('The password you entered is incorrect.');
    }
  };

  return (
    <div className={cn('app')}>
      <div className={cn('flexbox', 'justify-center', 'section-1')}>
        <div className={cn('has-text-centered')}>
          <img className={cn('logo')} src={logo} />
          <div className={cn('serif', 'my-4', 'is-size-1')}>Avant & Thomas</div>
          <div className={cn('authentic')}>
            <div>AUTHENTIC DIGITAL</div>
            <div>MASTERPIECES</div>
          </div>
          <div className={cn('mt-6')}>
            <button className={cn('button')} onClick={() => setIsModalOpen(true)}>
              Enter
            </button>
          </div>
        </div>
      </div>

      <div className={cn('modal', { 'is-active': isModalOpen })}>
        <div className="modal-background" onClick={() => setIsModalOpen(false)} />
        <div className={cn('modal-content', 'sign-in')}>
          <div className={cn('serif is-size-3', 'has-text-centered')}>Welcome</div>

          <div className={cn('mt-2')}>
            Please enter your Avant & Thomas password. If you do not have a password, please Register first.
          </div>
          <form onSubmit={onLogin}>
            {loginError && <div className={cn('is-size-7', 'has-text-danger', 'mt-4')}>{loginError}</div>}
            <div className={cn('mt-4')}>
              <button type="submit" className={cn('button')}>
                Go
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default Home;
