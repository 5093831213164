import { httpClient } from 'http/httpClient';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { PendingAction, RejectedAction } from 'state/store';
import { Platform, PLATFORM } from 'types';

export const fetchPlatform = createAsyncThunk('platform/fetchPlatform', async () => {
  const response = await httpClient.get(`/platforms/${PLATFORM}`, {});
  return response.data;
});

interface platformState {
  status: string;
  platform?: Platform;
  error?: string;
}

const initialState: platformState = {
  status: 'idle'
};

const platformSlice = createSlice({
  name: 'platform',
  initialState,
  reducers: {
    reset: (state) => {
      state = initialState;
      return state;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchPlatform.fulfilled, (state, action) => {
        state.status = 'success';
        state.platform = action.payload;
      })
      .addMatcher(
        (action): action is PendingAction => /platform\/(.*)\/pending/.test(action.type),
        (state) => {
          state.status = 'loading';
        }
      )
      .addMatcher(
        (action): action is RejectedAction => /platform\/(.*)\/rejected/.test(action.type),
        (state, action) => {
          state.error = 'rejected';
          state.error = action.error.message;
        }
      );
  }
});

export const { reset } = platformSlice.actions;
export default platformSlice.reducer;
