import React, { useEffect, useState } from 'react';
import { Link, Route, Routes, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import classnames from 'classnames/bind';

import { resetLoginError } from 'state/reducers/sessionReducer';
import { getCurrentUser, getPaymentProfiles, getCryptoWallets } from 'state/reducers/userReducer';
import { RootState } from 'state/store';
import { colorGenerator } from 'utils';

import Login from 'components/Login';
import Modal from 'components/Modal';
import Register from 'components/Register';
import Snackbar from 'components/Snackbar';
import About from 'routes/About';
import AccountBase from 'routes/Account';
import Profile from 'routes/Account/Profile';
import Security from 'routes/Account/Security';
import Wallet from 'routes/Account/Wallet/Wallet';
import CreditCards from 'routes/Account/CreditCards';
import History from 'routes/Account/History';
import ProfileCollections from 'routes/Account/Collections';
import Collaborator from 'routes/Collaborator';
import Collaborators from 'routes/Collaborators';
import Collection from 'routes/Collection';
import Collections from 'routes/Collections';
import Contact from 'routes/Contact';
import Home from 'routes/Home';
import Item from 'routes/Item';
import LoginBase from 'routes/LoginBase';
import Marketplace from 'routes/Marketplace';
import MarketplaceItem from 'routes/MarketplaceItem';

import Questions from 'routes/Questions';
import { setPopupState } from 'state/reducers/userReducer';
import logoBlack from 'assets/images/at-black.png';
import logoWhite from 'assets/images/at-white.png';
import defaultAvatar from 'assets/images/default-avatar.png';
import menuIcon from 'assets/images/icons/at-menu.svg';

import styles from './HomeBase.module.scss';

const cx = classnames.bind(styles);

const HomeBase = () => {
  const { session, user } = useSelector((state: RootState) => ({
    session: state.session,
    user: state.user
  }));
  const [isShowMenuMobile, setIsShowMenuMobile] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const resetLogin = () => {
    dispatch(setPopupState(null));
    dispatch(resetLoginError());
  };

  useEffect(() => {
    if (session.authToken) {
      dispatch(setPopupState(null));
      dispatch(getCurrentUser());
      dispatch(getPaymentProfiles());
      dispatch(getCryptoWallets());
    }
  }, [session.authToken]);

  const popupState = user.popupState;
  return (
    <>
      {popupState !== null && (
        <Modal size="sm" onClose={resetLogin}>
          {popupState === 'login' && <Login type="mod" onChange={(state) => dispatch(setPopupState(state))} />}
          {popupState === 'register' && <Register type="mod" onChange={(state) => dispatch(setPopupState(state))} />}
        </Modal>
      )}

      <Snackbar />

      <div className={cx('navigation', 'py-4')}>
        <div className={cx('container', 'flexbox', 'wrap')}>
          <div className={cx('title', 'flexbox')} onClick={() => navigate('/')}>
            <img className={cx('logo')} src={logoBlack} />
            <div className={cx('type', 'serif', 'is-size-3')}>Avant & Thomas</div>
          </div>
          <div className={cx('map')}>
            <button
              className={cx('button', 'is-outlined', 'mobile-menu')}
              onClick={() => setIsShowMenuMobile(!isShowMenuMobile)}>
              <img className={cx('icon', 'is-small')} src={menuIcon} />
            </button>
            <ul className={cx('list', 'inline', 'link', 'map-list')}>
              <div className={cx('flexbox', 'map-menu')}>
                <li onClick={() => navigate('/collections')}>COLLECTIONS</li>
                <li onClick={() => navigate('/collaborators')}>COLLABORATORS</li>
                <li onClick={() => navigate('/marketplace')}>MARKETPLACE</li>
                <li onClick={() => navigate('/about')}>ABOUT</li>
              </div>
              {!session.authToken ? (
                <div className={cx('flexbox', 'account')}>
                  <li onClick={() => dispatch(setPopupState('register'))}>
                    <button className={cx('button', 'is-outlined', 'clearance')}>JOIN</button>
                  </li>
                  <li onClick={() => dispatch(setPopupState('login'))}>
                    <button className={cx('button', 'clearance')}>LOGIN</button>
                  </li>
                </div>
              ) : (
                user.user && (
                  <li className={cx('account')} onClick={() => navigate('/account')}>
                    <object
                      className={cx('avatar')}
                      data={user.user?.profileImageUrl || defaultAvatar}
                      style={{ backgroundColor: colorGenerator(user.user?.username) }}
                    />
                  </li>
                )
              )}
            </ul>
          </div>
        </div>
        {isShowMenuMobile && (
          <div className={cx('mobile-mirage')}>
            <ul>
              <li
                onClick={() => {
                  setIsShowMenuMobile(false);
                  navigate('/collections');
                }}>
                COLLECTIONS
              </li>
              <li
                onClick={() => {
                  setIsShowMenuMobile(false);
                  navigate('/collaborators');
                }}>
                COLLABORATORS
              </li>
              <li
                onClick={() => {
                  setIsShowMenuMobile(false);
                  navigate('/marketplace');
                }}>
                MARKETPLACE
              </li>
              <li
                onClick={() => {
                  setIsShowMenuMobile(false);
                  navigate('/about');
                }}>
                ABOUT
              </li>
            </ul>
          </div>
        )}
      </div>
      <div className={cx('routes')}>
        <Routes>
          <Route path="/collection" element={<Collection />} />
          <Route path="/collection/:guid" element={<Collection />} />
          <Route path="/collections" element={<Collections />} />
          <Route path="/collaborator/:guid" element={<Collaborator />} />
          <Route path="/collaborators" element={<Collaborators />} />
          <Route path="/marketplace" element={<Marketplace />} />
          <Route path="/marketplace/:guid" element={<MarketplaceItem />} />
          <Route path="/piece/:postGroupGuid/:guid" element={<Item />} />
          <Route path="/about" element={<About />} />
          <Route path="/faq" element={<Questions />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/account" element={<AccountBase />}>
            <Route index element={<Profile />} />
            <Route path="profile" element={<Profile />} />
            <Route path="security" element={<Security />} />
            <Route path="wallets" element={<Wallet />} />
            <Route path="credit-cards" element={<CreditCards />} />
            <Route path="history" element={<History />} />
            <Route path="collections" element={<ProfileCollections />} />
          </Route>
          <Route path="/login" element={<LoginBase />} />
          <Route path="/" element={<Home />} />
        </Routes>
      </div>
      <div className={cx('footer')}>
        <div className={cx('container', 'height-full')}>
          <div className={cx('flexbox', 'wrap', 'height-full')}>
            <div className={cx('flexbox', 'flex')}>
              <img className={cx('logo')} src={logoWhite} />
              <div className={cx('serif', 'is-size-3', 'ml-2')}>Avant & Thomas</div>
            </div>
            <div className={cx('footer-menu')}>
              <div>
                <a href="https://www.avantthomas.com/assets/terms.pdf" target="_blank">
                  Terms of Service
                </a>
              </div>
              <div>
                <a href="https://www.avantthomas.com/assets/privacy.pdf" target="_blank">
                  Privacy Policy
                </a>
              </div>
              <div>
                <Link to="/faq">FAQs</Link>
              </div>
              <div>
                <Link to="/contact">Contact Us</Link>
              </div>
            </div>
          </div>
          <div className={cx('copyright', 'has-text-centered', 'is-size-7')}>
            <div className={cx('mb-1')}>Copyright © 2022 AvantThomas Llc.</div>
            <div>
              AvantThomas and the AT logo are trademarks of AvantThomas Llc. and may not be used without permission.
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default HomeBase;
