import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import classnames from 'classnames/bind';
import Payment from 'payment';
import Loading from 'components/Loading';
import { addPaymentProfile } from 'state/reducers/userReducer';
import { CreditCard } from 'types';
import { RootState } from 'state/store';
import visa from 'assets/images/credit-brands/cc-visa-brands.svg';
import mastercard from 'assets/images/credit-brands/cc-mastercard-brands.svg';
import amex from 'assets/images/credit-brands/cc-amex-brands.svg';

import styles from './CreditCardModal.module.scss';

const cx = classnames.bind(styles);
export interface CreditCardModalProps {
  onClose: () => void;
}
const CreditCardModal = (props: CreditCardModalProps) => {
  const { onClose } = props;
  const cardNumberRef = useRef<HTMLInputElement | null>(null);
  const cardExpiryRef = useRef<HTMLInputElement | null>(null);
  const cardCvvRef = useRef<HTMLInputElement | null>(null);
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors }
  } = useForm<CreditCard>();
  const dispatch = useDispatch();
  const status = useSelector((state: RootState) => state.user.status);
  const { ref: formCardNumberRef, ...formCardNumberRest } = register('creditCardNumber', { required: true });
  const { ref: formCardExpiryRef, ...formCardExpiryRest } = register('expirationDate', { required: true });
  const { ref: formCardCvvRef, ...formCardCvvRest } = register('cardCode', { required: true });
  const statusRef = useRef(status);

  useEffect(() => {
    if (status === 'fulfilled' && statusRef.current === 'pending') {
      onClose();
    }
    return () => {
      statusRef.current = status;
    };
  }, [status]);

  const onSubmitCreditCard = (data: CreditCard) => {
    data.creditCardNumber = data.creditCardNumber.replace(/\s/g, '');
    data.expirationDate = data.expirationDate.replace(/\s/g, '');
    dispatch(addPaymentProfile(data));
  };

  const showCreditCardBrand = () => {
    switch (Payment.fns.cardType(watch('creditCardNumber'))) {
      case 'visa':
        return visa;
      case 'mastercard':
        return mastercard;
      case 'amex':
        return amex;
      default:
        return undefined;
    }
  };

  useEffect(() => {
    Payment.formatCardNumber(cardNumberRef.current as HTMLInputElement);
    Payment.formatCardExpiry(cardExpiryRef.current as HTMLInputElement);
    Payment.formatCardCVC(cardCvvRef.current as HTMLInputElement);
  }, []);

  return (
    <>
      {status === 'pending' && <Loading />}
      <div className={cx('is-size-4', 'has-text-weight-bold', 'mb-4')}>Card Information</div>
      <form onSubmit={handleSubmit(onSubmitCreditCard)}>
        <div className={cx('field')}>
          <label className={cx('label')}>Card number</label>
          <div className={cx('control', 'is-relative')}>
            <input
              className={cx('input', { 'is-danger': errors.creditCardNumber })}
              ref={(e) => {
                formCardNumberRef(e);
                cardNumberRef.current = e;
              }}
              {...formCardNumberRest}
            />
            <img className={cx('credit-brand')} src={showCreditCardBrand()} />
          </div>
        </div>

        <div className={cx('field')}>
          <label className={cx('label')}>Name on card</label>
          <div className={cx('control')}>
            <input
              className={cx('input', { 'is-danger': errors.firstName })}
              {...register('firstName', { required: true })}
            />
          </div>
        </div>

        <div className={cx('columns')}>
          <div className={cx('column')}>
            <div className={cx('field')}>
              <label className={cx('label')}>Expiration</label>
              <div className={cx('control')}>
                <input
                  className={cx('input', { 'is-danger': errors.expirationDate })}
                  placeholder="MM / YY"
                  ref={(e) => {
                    formCardExpiryRef(e);
                    cardExpiryRef.current = e;
                  }}
                  {...formCardExpiryRest}
                />
              </div>
            </div>
          </div>

          <div className={cx('column')}>
            <div className={cx('field')}>
              <label className={cx('label')}>CVV</label>
              <div className={cx('control')}>
                <input
                  type="password"
                  className={cx('input', { 'is-danger': errors.cardCode })}
                  ref={(e) => {
                    formCardCvvRef(e);
                    cardCvvRef.current = e;
                  }}
                  {...formCardCvvRest}
                />
              </div>
            </div>
          </div>

          <div className={cx('column')}>
            <div className={cx('field')}>
              <label className={cx('label')}>Zip code</label>
              <div className={cx('control')}>
                <input
                  className={cx('input', { 'is-danger': errors.zipCode })}
                  {...register('zipCode', { required: true })}
                />
              </div>
            </div>
          </div>
        </div>

        <button type="submit" className={cx('button')} disabled={status === 'pending'}>
          Save card
        </button>
      </form>
    </>
  );
};

export default CreditCardModal;
