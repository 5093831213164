import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classnames from 'classnames/bind';

import { getPaymentProfiles, makeDefaultPaymentProfile, removePaymentProfile } from 'state/reducers/userReducer';
import { RootState } from 'state/store';
import CreditCardList from 'components/CreditCardList';
import Modal from 'components/Modal';
import CreditCardModal from 'components/Modal/CreditCardModal';
import Skeleton from 'components/Skeleton';
import { PaymentProfile } from 'types';

import styles from './CreditCards.module.scss';

const cx = classnames.bind(styles);

const CreditCards = () => {
  const { user } = useSelector((state: RootState) => ({
    user: state.user
  }));
  const status = useSelector((state: RootState) => state.user.status);
  const dispatch = useDispatch();
  const [showCardModal, setShowCardModal] = useState(false);
  const [selectedCard, setSelectedCard] = useState<PaymentProfile | null>(null);

  useEffect(() => {
    dispatch(getPaymentProfiles());
  }, []);

  const handleDelete = () => {
    const answer = confirm('Are you sure you want to remove this card?');
    if (answer) {
      dispatch(removePaymentProfile(selectedCard as PaymentProfile));
    }
  };

  const handleDefault = (card: PaymentProfile) => {
    dispatch(makeDefaultPaymentProfile(card.guid));
    setSelectedCard(null);
  };

  return (
    <>
      <div className={cx('has-text-weight-bold', 'is-size-4', 'mb-4')}>Credit Cards</div>
      <div>
        {user.paymentProfiles ? (
          user.paymentProfiles.length > 0 ? (
            <div className={cx('card-grid')}>
              {user.paymentProfiles.map((card: PaymentProfile) => (
                <div key={card.guid} className={cx('card-cell')}>
                  <CreditCardList
                    isSelected={selectedCard?.guid === card.guid}
                    card={card}
                    onSelect={(c) => setSelectedCard(c)}
                  />
                  {selectedCard?.guid === card.guid && (
                    <div className={cx('options')}>
                      <button className={cx('button', 'is-outlined', 'is-small')} onClick={() => handleDefault(card)}>
                        Make default
                      </button>
                      <button
                        className={cx('button', 'is-outlined', 'is-small', 'ml-2')}
                        onClick={() => {
                          handleDelete();
                        }}>
                        Delete
                      </button>
                    </div>
                  )}
                </div>
              ))}
            </div>
          ) : (
            <div className={cx('has-text-centered')}></div>
          )
        ) : (
          <div className={cx('flexbox')}>
            <Skeleton type="rect" width="192px" height="120px" className={cx('mr-5')} />
            <Skeleton type="rect" width="192px" height="120px" />
          </div>
        )}
      </div>

      {user.paymentProfiles && (
        <button className={cx('button')} onClick={() => setShowCardModal(true)} disabled={status === 'pending'}>
          Add Card
        </button>
      )}

      {showCardModal && (
        <Modal onClose={() => setShowCardModal(false)}>
          <CreditCardModal onClose={() => setShowCardModal(false)} />
        </Modal>
      )}
    </>
  );
};

export default CreditCards;
