import React, { ChangeEvent, useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useSelector, useDispatch } from 'react-redux';
import classnames from 'classnames/bind';

import { colorGenerator } from 'utils';

import { updateUserProfile, updateUserProfileImage } from 'state/reducers/userReducer';
import { RootState } from 'state/store';

import defaultAvatar from 'assets/images/default-avatar.png';

import styles from './Profile.module.scss';

const cx = classnames.bind(styles);

const Profile = () => {
  const dispatch = useDispatch();
  const { user, status } = useSelector((state: RootState) => state.user);
  const { register, reset, handleSubmit } = useForm({ defaultValues: user });
  const profileImageFile = useRef<File | undefined>();
  const [previewUrl, setPreviewUrl] = useState<string | undefined>(undefined);

  const isLoading = status === 'pending';
  const fileInputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    reset(user);
  }, [user]);

  const onSubmit = handleSubmit((data) => {
    if (profileImageFile.current) {
      const formData = new FormData();
      formData.append('file', profileImageFile.current as Blob);
      dispatch(updateUserProfileImage({ userGuid: user?.guid as string, file: formData }));
    }
    dispatch(updateUserProfile(data));
  });

  const previewProfileImage = (fileInput: ChangeEvent) => {
    profileImageFile.current = ((fileInput.target as HTMLInputElement).files as FileList)[0];
    const mimeType = profileImageFile.current.type;
    if (mimeType?.match(/image\/*/) == null) {
      profileImageFile.current = undefined;
      return;
    } else {
      const reader = new FileReader();
      reader.readAsDataURL(profileImageFile.current);
      reader.onload = () => {
        setPreviewUrl(reader.result?.toString());
      };
    }
  };

  // eslint-disable-next-line no-console
  console.log(previewUrl, user?.profileImageUrl, colorGenerator(user?.username || ''));

  return (
    <>
      <div className={cx('has-text-weight-bold', 'is-size-4', 'mb-4')}>Profile</div>
      <form onSubmit={onSubmit}>
        <div className={cx('columns', 'has-mobile-view')}>
          <div className={cx('column', 'is-two-thirds')}>
            <div className={cx('field')}>
              <label className={cx('label')}>Display Name: (what people see)</label>
              <div className={cx('control')}>
                <input className={cx('input')} {...register('displayName')} />
              </div>
            </div>
            <div className={cx('field')}>
              <label className={cx('label')}>Username: (what you log in with)</label>
              <div className={cx('control')}>
                <input className={cx('input')} {...register('username')} />
              </div>
            </div>
            <div className={cx('field')}>
              <label className={cx('label')}>Email Address:</label>
              <div className={cx('control')}>
                <input className={cx('input')} {...register('emailAddress')} />
              </div>
            </div>
            <div className={cx('field')}>
              <label className={cx('label')}>Phone Number:</label>
              <div className={cx('control')}>
                <input className={cx('input')} {...register('phoneNumber')} />
              </div>
            </div>
            <div className={cx('field')}>
              <label className={cx('label')}>About</label>
              <div className={cx('control')}>
                <textarea className={cx('input', 'textarea')} rows={3} {...register('description')} />
              </div>
            </div>
            <div className={cx('flexbox', 'ft-mt-4')}>
              <button className={cx('button', { 'is-loading': isLoading })} disabled={isLoading}>
                Save changes
              </button>
            </div>
          </div>
          <div className={cx('column')}>
            <div className={cx('profile-avatar')}>
              <div className={cx('profile-avatar-label')}>Profile Picture</div>
              <div className={cx('profile-picture')}>
                <object
                  className={cx('avatar')}
                  data={previewUrl || user?.profileImageUrl || defaultAvatar}
                  style={{ backgroundColor: colorGenerator(user?.username || '') }}
                />
                <div>
                  <button
                    className={cx('button', 'is-outlined is-small', 'mt-2')}
                    type="button"
                    onClick={() => fileInputRef.current?.click()}>
                    Change Avatar
                  </button>
                </div>
                <input type="file" className={cx('hidden')} onChange={previewProfileImage} ref={fileInputRef} />
              </div>
            </div>
          </div>
        </div>
      </form>
    </>
  );
};

export default Profile;
