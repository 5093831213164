import { httpClient } from 'http/httpClient';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { PendingAction, RejectedAction } from 'state/store';
import { Merchant } from 'types/merchant';
import { cloneDeep } from 'lodash';
import { PLATFORM } from 'types';

export const fetchMerchants = createAsyncThunk('merchant/fetchMerchants', async () => {
  const response = await httpClient.get('/merchants/search', {
    params: {
      platform: PLATFORM
    }
  });
  return response.data;
});

export const fetchMerchantsWithCollections = createAsyncThunk('merchant/fetchMerchantsWithCollections', async () => {
  return await httpClient
    .get('/merchants/search', {
      params: {
        platform: PLATFORM
      }
    })
    .then((response) => {
      const merchant: Merchant[] = response.data;
      return new Promise<Merchant[]>((resolve) => {
        Promise.allSettled(
          merchant.map((c: Merchant) =>
            httpClient.get('/public/posts/search', {
              params: { platform: PLATFORM, postType: 'PostGroup', userGUID: c.userGUID }
            })
          )
        ).then((promise) => {
          const collection = cloneDeep(merchant);
          promise.forEach((item, index: number) => {
            if (item.status === 'fulfilled') {
              collection[index].collections = item.value.data;
              resolve(collection);
            }
          });
        });
      });
    });
});

export const fetchMerchant = createAsyncThunk('merchant/fetchMerchant', async (guid: string) => {
  const response = await httpClient.get('/merchants/search', {
    params: {
      platform: PLATFORM,
      guid
    }
  });
  return response.data;
});

export const fetchMerchantWithCollection = createAsyncThunk(
  'merchant/fetchMerchantWithCollection',
  async (guid: string) => {
    return await httpClient
      .get('/merchants/search', {
        params: {
          platform: PLATFORM,
          guid: guid
        }
      })
      .then((response) => {
        const merchant: Merchant[] = response.data;
        return new Promise<Merchant[]>((resolve) => {
          Promise.allSettled(
            merchant.map((c: Merchant) =>
              httpClient.get('/public/posts/search', {
                params: { platform: PLATFORM, postType: 'PostGroup', userGUID: c.userGUID }
              })
            )
          ).then((promise) => {
            const collection = cloneDeep(merchant);
            promise.forEach((item, index: number) => {
              if (item.status === 'fulfilled') {
                collection[index].collections = item.value.data;
                resolve(collection);
              }
            });
          });
        });
      });
  }
);

export const fetchFeaturedMerchants = createAsyncThunk('merchant/fetchFeaturedMerchants', async () => {
  const response = await httpClient.get('/merchants/search', {
    params: {
      platform: PLATFORM,
      isFeatured: true
    }
  });
  return response.data;
});

interface MerchantState {
  status: string;
  merchants?: Merchant[];
  featuredMerchants?: Merchant;
  merchant?: Merchant;
  error?: string;
}

const initialState: MerchantState = {
  status: 'idle'
};

const merchantSlice = createSlice({
  name: 'merchant',
  initialState,
  reducers: {
    reset: (state) => {
      state = initialState;
      return state;
    },
    setMerchant: (state, action) => {
      state.merchant = action.payload;
    },
    clearMerchant: (state) => {
      state.merchant = undefined;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchMerchants.fulfilled, (state, action) => {
        state.status = 'success';
        state.merchants = action.payload;
      })
      .addCase(fetchMerchantsWithCollections.fulfilled, (state, action) => {
        state.status = 'success';
        state.merchants = action.payload;
      })
      .addCase(fetchMerchant.fulfilled, (state, action) => {
        state.status = 'success';
        state.merchant = action.payload[0];
      })
      .addCase(fetchMerchantWithCollection.fulfilled, (state, action) => {
        state.status = 'success';
        state.merchant = action.payload[0];
      })
      .addCase(fetchFeaturedMerchants.fulfilled, (state, action) => {
        state.status = 'success';
        if (action.payload.length) {
          state.featuredMerchants = action.payload[0];
        }
      })
      .addMatcher(
        (action): action is PendingAction => /merchant\/(.*)\/pending/.test(action.type),
        (state) => {
          state.status = 'loading';
        }
      )
      .addMatcher(
        (action): action is RejectedAction => /merchant\/(.*)\/rejected/.test(action.type),
        (state, action) => {
          state.error = 'rejected';
          state.error = action.error.message;
        }
      );
  }
});

export const { reset, setMerchant, clearMerchant } = merchantSlice.actions;
export default merchantSlice.reducer;
