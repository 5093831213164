/* eslint-disable no-console */
import { httpClient } from 'http/httpClient';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
// import classnames from 'classnames/bind';
import Modal from 'components/Modal';
import Loading from 'components/Loading';
import { openSnackbar } from 'state/reducers/snackbarReducer';
import { User } from 'types';
// import styles from './TransferModal.module.scss';

// const cx = classnames.bind(styles);

export interface TransferModalProp {
  onClose: () => void;
  handleTransfer: (userGUID: string) => void;
}

const TransferModal = (props: TransferModalProp) => {
  const dispatch = useDispatch();
  const { onClose, handleTransfer } = props;
  const [loading, setLoading] = useState(false);
  const [userName, setUserName] = useState<string>('');
  const [userGUID, setUserGUID] = useState<string>();
  const [accepted, setAccepted] = useState(false);
  const validateUsername = async () => {
    if (userName) {
      setLoading(true);
      try {
        const resp = await httpClient.get(`users/search?username=${userName}`);
        const users = resp.data as User[];
        if (users.length === 0) {
          dispatch(
            openSnackbar({
              message: `${userName} not found!`,
              type: 'danger'
            })
          );
          return;
        }
        setUserGUID(users[0].guid as string);
      } catch (e) {
        dispatch(
          openSnackbar({
            message: e + '',
            type: 'danger'
          })
        );
      } finally {
        setLoading(false);
      }
    }
  };

  const handleTransferClick = () => {
    handleTransfer(userGUID as string);
    onClose();
  };

  return (
    <>
      <Modal onClose={onClose}>
        {loading && <Loading />}
        <div className="is-flex  is-align-items-center mt-6 my-3">
          <input
            className="input"
            onChange={(ev) => setUserName(ev.target.value)}
            placeholder="Please type username..."
          />
          <button className="button is-primary ml-4" onClick={validateUsername} disabled={!userName}>
            Confirm
          </button>
        </div>
        {userGUID && (
          <div className="is-flex is-flex-direction-column">
            <div className="notification is-warning mb-4">This transfer is final, and it's not reversible.</div>
            <label className="checkbox is-size-7 mb-4">
              <input type="checkbox" checked={accepted} onChange={(e) => setAccepted(e.target.checked)} /> I agree that
              I transfer this NFT to {userName}.
            </label>
            <button className="button is-danger mb-4" disabled={!accepted} onClick={handleTransferClick}>
              Transfer
            </button>
          </div>
        )}
      </Modal>
    </>
  );
};

export default TransferModal;
