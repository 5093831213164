import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate, useParams } from 'react-router-dom';
import classnames from 'classnames/bind';
import { clearMerchant, setMerchant } from 'state/reducers/merchantReducer';
import {
  createView,
  fetchCollectionById,
  fetchCollectionItem,
  resetItem,
  setMerchantCollection
} from 'state/reducers/postReducer';
import { RootState } from 'state/store';

import Skeleton from 'components/Skeleton';
import Timer from 'components/Timer';
import PostItem from 'components/PostItem';
import { Post } from 'types';

import moment from 'moment';
import styles from './Collection.module.scss';

const cx = classnames.bind(styles);

const Collection = () => {
  const { post, merchant } = useSelector((state: RootState) => ({
    post: state.post,
    merchant: state.merchant
  }));
  const { guid } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const viewPiece = (post: Post) => {
    dispatch(resetItem());
    navigate(`/piece/${guid}/${post.guid}`);
  };

  const checkMerchantState = (merchantGuid: string) => {
    const index = merchant.merchants?.findIndex((m) => m.guid === merchantGuid);
    if (index !== undefined && merchant.merchants) {
      dispatch(setMerchant(merchant.merchants[index]));
    }
  };
  const incrementViews = (post?: Post) => {
    const view = {
      merchantGUID: post?.merchantGUID,
      postGUID: post?.guid,
      dateViewed: moment().format('MM/DD/YY hh:mm:ss')
    };

    dispatch(createView(view));
  };
  useEffect(() => {
    if (guid && post.collection?.guid !== guid) {
      dispatch(fetchCollectionById(guid));
    }
    if (guid && post.collection?.items === undefined) {
      dispatch(fetchCollectionItem(guid));
    }
  }, [guid]);

  useEffect(() => {
    if (post.collection) {
      incrementViews(post.collection);
    }
  }, []);

  // eslint-disable-next-line no-console
  console.log(post.collection?.items);

  return (
    <>
      <div className={cx('banner')}>
        <img src={post.collection?.featuredImageUrl} />
      </div>
      <div className="container">
        <div className={cx('ocean')}>
          <div className={cx('has-text-centered')}>
            <div className={cx('has-text-weight-semibold', 'mt-4')}>COLLECTION</div>
            <div className={cx('collaborator', 'is-size-2', 'has-text-weight-bold', 'mb-3')}>
              {post.collection?.name}
            </div>
            <div className={cx('flexbox', 'justify-center')}>
              <img className={cx('avatar')} src={post.collection?.profileImageUrl} />
              <Link
                to={`/collaborator/${post.collection?.merchantGUID}`}
                onClick={() => {
                  dispatch(setMerchantCollection(undefined));
                  dispatch(clearMerchant());
                  post.collection?.merchantGUID && checkMerchantState(post.collection?.merchantGUID);
                }}>
                <div className={cx('has-text-weight-bold', 'ml-2')}>
                  {post.collection?.merchantName || post.collection?.userUsername}
                </div>
              </Link>
            </div>
          </div>
          <div className={cx('description', 'mt-4')}>{post.collection?.description}</div>
          {post.collection?.dateAvailable && (
            <div className={cx('has-text-centered', 'mt-6')}>
              <div className={cx('has-text-weight-semibold', 'is-size-6', 'mb-2')}>Drop starts in</div>
              <div className={cx('flexbox', 'justify-center')}>
                <Timer type={1} date={post.collection.dateAvailable} />
              </div>
            </div>
          )}

          <div className="trance-wrapper mt-6">
            <div className="trance">
              {post.collection?.items !== undefined ? (
                <>
                  {post.collection?.items.length > 0 ? (
                    post.collection.items.map(
                      (post: Post) =>
                        (post.marketplaceListingETHAmount || post.marketplaceListingUSDAmount) && (
                          <PostItem post={post} viewPiece={viewPiece} key={post.guid} />
                        )
                    )
                  ) : (
                    <div
                      className={cx(
                        'is-size-4',
                        'has-text-weight-bold',
                        'width-full',
                        'has-text-centered',
                        'has-text-muted',
                        'py-6'
                      )}>
                      All items in this collection are sold out.
                    </div>
                  )}
                </>
              ) : (
                <>
                  <Skeleton className={cx('collection-skeleton')} type="rect" height="356px" width="calc(25% - 1rem)" />
                  <Skeleton className={cx('collection-skeleton')} type="rect" height="356px" width="calc(25% - 1rem)" />
                  <Skeleton className={cx('collection-skeleton')} type="rect" height="356px" width="calc(25% - 1rem)" />
                  <Skeleton className={cx('collection-skeleton')} type="rect" height="356px" width="calc(25% - 1rem)" />
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Collection;
