import { ethers } from 'ethers';
import { WalletSource, CryptoType } from 'types';

export const getMetaMaskInfo = async () => {
  if (!window.ethereum) throw new Error('No crypto wallet found. Please install it.');

  await window.ethereum.request({ method: 'eth_requestAccounts' });
  const provider = new ethers.providers.Web3Provider(window.ethereum);

  const network = await provider.getNetwork();
  if (`${network.chainId}` !== process.env.REACT_APP_CHAIN_ID) {
    throw new Error(`Invalid network, Please select ${process.env.REACT_APP_NETWORK_NAME}.`);
  }

  const signer = provider.getSigner();
  const wallet = {
    publicAddress: await signer.getAddress(),
    source: WalletSource.METAMASK,
    blockchainType: CryptoType.ETHEREUM,
    isManaged: false,
    platform: 'AvantThomas'
  };

  return { signer, wallet };
};
