import React, { useEffect, useRef } from 'react';
import { useForm } from 'react-hook-form';
import classnames from 'classnames/bind';
import Payment from 'payment';

import { CreditCard } from 'types';

import visa from 'assets/images/credit-brands/cc-visa-brands.svg';
import mastercard from 'assets/images/credit-brands/cc-mastercard-brands.svg';
import amex from 'assets/images/credit-brands/cc-amex-brands.svg';

import styles from './CreditCardForm.module.scss';

const cx = classnames.bind(styles);

interface CreditCardFormProps {
  data: (value: CreditCard) => void;
}

const CreditCardForm = (props: CreditCardFormProps) => {
  const cardNumberRef = useRef<HTMLInputElement | null>(null);
  const cardExpiryRef = useRef<HTMLInputElement | null>(null);
  const cardCvvRef = useRef<HTMLInputElement | null>(null);
  const {
    getValues,
    register,
    watch,
    formState: { errors }
  } = useForm<CreditCard>();

  const { ref: formCardNumberRef, ...formCardNumberRest } = register('creditCardNumber', { required: true });
  const { ref: formCardExpiryRef, ...formCardExpiryRest } = register('expirationDate', { required: true });
  const { ref: formCardCvvRef, ...formCardCvvRest } = register('cardCode', { required: true });

  const showCreditCardBrand = () => {
    switch (Payment.fns.cardType(watch('creditCardNumber'))) {
      case 'visa':
        return visa;
      case 'mastercard':
        return mastercard;
      case 'amex':
        return amex;
      default:
        return undefined;
    }
  };

  const handleChange = () => {
    props.data(getValues());
  };

  useEffect(() => {
    Payment.formatCardNumber(cardNumberRef.current as HTMLInputElement);
    Payment.formatCardExpiry(cardExpiryRef.current as HTMLInputElement);
    Payment.formatCardCVC(cardCvvRef.current as HTMLInputElement);
  }, []);

  return (
    <form onChange={handleChange}>
      <div className={cx('is-size-4', 'has-text-weight-bold', 'mb-4')}>Card Information</div>
      <div className={cx('field')}>
        <label className={cx('label')}>Card number</label>
        <div className={cx('control', 'is-relative')}>
          <input
            className={cx('input', { 'is-danger': errors.creditCardNumber })}
            ref={(e) => {
              formCardNumberRef(e);
              cardNumberRef.current = e;
            }}
            {...formCardNumberRest}
          />
          <img className={cx('credit-brand')} src={showCreditCardBrand()} />
        </div>
      </div>

      <div className={cx('field')}>
        <label className={cx('label')}>Name on card</label>
        <div className={cx('control')}>
          <input
            className={cx('input', { 'is-danger': errors.firstName })}
            {...register('firstName', { required: true })}
          />
        </div>
      </div>

      <div className={cx('columns')}>
        <div className={cx('column')}>
          <div className={cx('field')}>
            <label className={cx('label')}>Expiration</label>
            <div className={cx('control')}>
              <input
                className={cx('input', { 'is-danger': errors.expirationDate })}
                placeholder="MM / YY"
                ref={(e) => {
                  formCardExpiryRef(e);
                  cardExpiryRef.current = e;
                }}
                {...formCardExpiryRest}
              />
            </div>
          </div>
        </div>

        <div className={cx('column')}>
          <div className={cx('field')}>
            <label className={cx('label')}>CVV</label>
            <div className={cx('control')}>
              <input
                type="password"
                className={cx('input', { 'is-danger': errors.cardCode })}
                ref={(e) => {
                  formCardCvvRef(e);
                  cardCvvRef.current = e;
                }}
                {...formCardCvvRest}
              />
            </div>
          </div>
        </div>

        <div className={cx('column')}>
          <div className={cx('field')}>
            <label className={cx('label')}>Zip code</label>
            <div className={cx('control')}>
              <input
                className={cx('input', { 'is-danger': errors.zipCode })}
                {...register('zipCode', { required: true })}
              />
            </div>
          </div>
        </div>
      </div>
    </form>
  );
};

export default CreditCardForm;
