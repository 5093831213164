import React, { useEffect, useState } from 'react';
import classnames from 'classnames/bind';

import styles from './Checkbox.module.scss';

const cn = classnames.bind(styles);

interface CheckboxProps {
  label?: string;
  name: string;
  onChange?: (value: boolean) => unknown;
}

const Checkbox = (props: CheckboxProps) => {
  const [isSelected, setIsSelected] = useState(false);

  const toggleIsSelected = () => {
    setIsSelected(!isSelected);
  };

  useEffect(() => {
    if (props.onChange) {
      props.onChange(isSelected);
    }
  }, [isSelected]);

  return (
    <div className={cn('control')} onClick={toggleIsSelected}>
      <div className={cn('flexbox')}>
        <div className={cn('checkbox', { selected: isSelected })}>
          <i className="material-icons-outlined">{isSelected ? 'check_box' : 'check_box_outline_blank'}</i>
        </div>
        <label className={cn('label')}>{props.label}</label>
      </div>
      <input className={cn('hidden')} type="checkbox" name={props.name} checked={isSelected} readOnly />
    </div>
  );
};

export default Checkbox;
