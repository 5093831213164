import { httpClient } from 'http/httpClient';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import classnames from 'classnames/bind';
import { PurchasedMarketplaceListing } from 'types';
import DateTime from 'components/DateTime';
import Loading from 'components/Loading';
import { openSnackbar } from 'state/reducers/snackbarReducer';
import { RootState } from 'state/store';
import { getPrice } from 'utils/marketplaceListing';
import { PLATFORM } from 'types';
import styles from './History.module.scss';

const cx = classnames.bind(styles);

const History = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState<PurchasedMarketplaceListing[]>([]);
  const userGUID = useSelector((state: RootState) => state.user.user?.guid);

  const loadHistoryData = async () => {
    try {
      setLoading(true);
      const response = await httpClient.get(`/users/current/marketplacelistings?platform=${PLATFORM}`);
      setData(response.data);
      setLoading(false);
    } catch (e) {
      dispatch(
        openSnackbar({
          message: e + '',
          type: 'danger'
        })
      );
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    loadHistoryData();
  }, []);

  if (loading) return <Loading />;
  return (
    <>
      {!loading && data.length === 0 && (
        <div className={cx('no-history')}>
          <p>
            You currently have no NFTs. Start collecting today by viewing some of our partner collections{' '}
            <Link to="/">here.</Link>
          </p>
        </div>
      )}
      {!loading && data.length > 0 && (
        <table className={cx('table', 'history-table')}>
          <thead>
            <tr>
              <th>Type</th>
              <th></th>
              <th>Amount</th>
              <th>From/To</th>
              <th>Collection</th>
              <th>Details</th>
              <th>Date</th>
            </tr>
          </thead>
          <tbody>
            {data.map((item) => (
              <tr key={item.guid}>
                <td>{item.buyerUserGUID === userGUID ? 'Purchase' : 'Resell'}</td>
                <td>
                  <img src={item.postNormalizedContentUrl} className={cx('profile-img')} alt="profile img" />
                </td>
                <td>{getPrice(item)}</td>
                <td>{item.buyerUserGUID === userGUID ? item.sellerUserDisplayName : item.buyerUserDisplayName}</td>
                <td>{item.postName}</td>
                <td>
                  <p className={cx('description')}>{item.postDescription}</p>
                </td>
                <td>
                  <DateTime date={item.dateSold} />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </>
  );
};

export default History;
