import React, { useState } from 'react';
import classnames from 'classnames/bind';

import Login from 'components/Login';
import Register from 'components/Register';

import styles from './LoginBase.module.scss';

const cx = classnames.bind(styles);

function LoginBase() {
  const [loginState, setLoginState] = useState('login');

  return (
    <div className={cx('container', 'stretch')}>
      <div className={cx('wrapper')}>
        <div className={cx('login')}>
          {loginState === 'login' && <Login type="red" onChange={setLoginState} />}
          {loginState === 'register' && <Register type="red" onChange={setLoginState} />}
        </div>
      </div>
    </div>
  );
}

export default LoginBase;
