export interface Platform {
  key: string;
  displayName: string;
  description: string;
  baseUrl: string;
  requireReferralCode?: boolean;
  enablePublicAccess?: boolean;
  featuredSection: string;
}

export const PLATFORM = process.env.REACT_APP_PRIMARY_PLATFORM;
