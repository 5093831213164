import React from 'react';
import classnames from 'classnames/bind';

import styles from './Questions.module.scss';

const cx = classnames.bind(styles);

const Questions = () => {
  return (
    <div className={cx('container', 'ocean', 'pt-6')}>
      <div className={cx('has-text-weight-bold', 'has-text-primary', 'is-size-3')}>Frequently Asked Questions</div>
      <div className={cx('mt-4')}>
        <div className={cx('has-text-weight-bold has-text-primary is-size-5 mt-5')}>What is Avant & Thomas?</div>
        <div className={cx('mt-4')}>
          Avant & Thomas is a curated NFT marketplace working with some of the biggest names in the entertainment and
          sports industry. We only work with the original creators and guarantee that their NFT's are unique and
          authentic.
        </div>
      </div>
      <div className={cx('mt-4')}>
        <div className={cx('has-text-weight-bold has-text-primary is-size-5 mt-5')}>What is an NFT?</div>
        <div className={cx('mt-4')}>
          Non-fungible tokens (NFTs) are a 'smart contract' that verifies the ownership of an asset, such as an image,
          video or music file. Since it is recorded on the blockchain, this ownership can never be hacked or changed in
          any way. This guarantees that whoever is holding that NFT, is the only one that has the original file and the
          only one in a position to sell or otherwise trade the file. For example, in the real world there is only one
          Mona Lisa, which is housed at the Louvre in Paris. However, there are tens-of thousands of copies hanging in
          living rooms and offices around the world. If someone tried to sell one of those copies, they would not have
          the 'papers' to guarantee that they had the original and were the original owners. NFT smart contracts solves
          this problem in the digital world.
        </div>
      </div>
      <div className={cx('mt-4')}>
        <div className={cx('has-text-weight-bold has-text-primary is-size-5 mt-5')}>
          Can I buy an NFT with cash or crypto?
        </div>
        <div className={cx('mt-4')}>
          Yes, you can. We support both credit card payments as well as the Ethereum cryptocurrency ETH.{' '}
        </div>
      </div>
      <div className={cx('mt-4')}>
        <div className={cx('has-text-weight-bold has-text-primary is-size-5 mt-5')}>
          Do I need a digital Wallet to buy and Avant & Thomas NFT?
        </div>
        <div className={cx('mt-4')}>
          You do need a digital wallet to store your NFTs. When you create an Avant & Thomas account, we automatically
          create an Avant & Thomas digital wallet for you to hold your NFTs. If you already have a wallet that you
          prefer to use, then you can can always connect that wallet using Metamask.
        </div>
      </div>
      <div className={cx('mt-4')}>
        <div className={cx('has-text-weight-bold has-text-primary is-size-5 mt-5')}>Are NFTs refundable?</div>
        <div className={cx('mt-4')}>
          No, they are not. You can however sell your NFT on the Avant & Thomas marketplace. There is no guarantee that
          you will get the same or an even higher price than what you paid.
        </div>
      </div>
      <div className={cx('mt-4')}>
        <div className={cx('has-text-weight-bold has-text-primary is-size-5 mt-5')}>
          Do Avant & Thomas NFTs go up in value?
        </div>
        <div className={cx('mt-4')}>
          Avant & Thomas does not control or set any prices; these are done by the owners of the NFTs. An NFT should not
          be purchased with the expectation to make any profits or gains in the future.
        </div>
      </div>
      <div className={cx('mt-4')}>
        <div className={cx('has-text-weight-bold has-text-primary is-size-5 mt-5')}>
          How do I sell my Avant & Thomas NFT?
        </div>
        <div className={cx('mt-4')}>
          You can buy, sell and trade NFT's on the Avant & Thomas NFT Marketplace. The marketplace is scheduled to go
          live in July 2022.
        </div>
      </div>
      <div className={cx('mt-4')}>
        <div className={cx('has-text-weight-bold has-text-primary is-size-5 mt-5')}>
          Can I list own my work on Avant & Thomas?
        </div>
        <div className={cx('mt-4')}>
          At Avant & Thomas we love to hear from creative people that are interested in submitting their work for
          display. As we are a curated marketplace, all submissions need to be verified and authenticated by Avant &
          Thomas. If you are interested, please reach out to Jessica who handles our Creator partnerships.
        </div>
      </div>
      <div className={cx('mt-4')}>
        <div className={cx('has-text-weight-bold has-text-primary is-size-5 mt-5')}>
          What is the Avant & Thomas Founding Membership?
        </div>
        <div className={cx('mt-4')}>
          Avant & Thomas is offering one thousand unique NFTs that are tied to specific benefits a holder gets by being
          an Avant & Thomas Founding member. For example, they may get early access to drops from notable artists or
          creators, or free entry to exclusive events and shows put on by Avant & Thomas. These benefits will change
          over time and there are no guarantees that these benefits will have any monetary value.
        </div>
      </div>
    </div>
  );
};

export default Questions;
