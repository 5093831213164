import { httpClient } from 'http/httpClient';
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import classnames from 'classnames/bind';
import { RootState } from 'state/store';
import { openSnackbar } from 'state/reducers/snackbarReducer';
import Loading from 'components/Loading';
import DateTime from 'components/DateTime';
import { MarketlistingNFT } from 'types';
import { getPrice } from 'utils/marketplaceListing';
import styles from './ItemActivity.module.scss';

const cx = classnames.bind(styles);

const ItemActivity = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<MarketlistingNFT[]>([]);

  const { post } = useSelector((state: RootState) => ({
    post: state.post
  }));

  useEffect(() => {
    if (post.item?.guid) {
      (async () => {
        try {
          setLoading(true);
          const resp = await httpClient.get(`/posts/${post.item?.guid}/marketplacelistings`);
          setData(resp.data);
        } catch (e) {
          dispatch(
            openSnackbar({
              message: e + '',
              type: 'danger'
            })
          );
        } finally {
          setLoading(false);
        }
      })();
    }
  }, [post.item?.guid]);

  const eventName = (item: MarketlistingNFT) => {
    if (item.sellerUserGUID && item.buyerUserGUID) return 'Sale';
    if (item.sellerUserGUID) return 'List';
    return '';
  };

  return (
    <div className={cx('container')}>
      {loading && <Loading />}
      <table className="table">
        <thead>
          <tr>
            <th>Event</th>
            <th>Price</th>
            <th>From</th>
            <th>To</th>
            <th>Date</th>
          </tr>
        </thead>
        <tbody>
          {data.map((item) => (
            <tr key={item.guid}>
              <td>{eventName(item)}</td>
              <td>{getPrice(item)}</td>
              <td>{item?.sellerUserDisplayName || ''}</td>
              <td>{item?.buyerUserDisplayName || ''}</td>
              <td>
                <DateTime date={item.dateSold || item.dateCreated || ''} />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default ItemActivity;
