import { AnyAction, AsyncThunk, combineReducers, configureStore, Reducer } from '@reduxjs/toolkit';
import { persistReducer, persistStore } from 'redux-persist';
import localForage from 'localforage';

import sessionReducer from 'state/reducers/sessionReducer';
import postReducer from 'state/reducers/postReducer';
import merchantReducer from 'state/reducers/merchantReducer';
import snackbarReducer from 'state/reducers/snackbarReducer';
import userReducer from 'state/reducers/userReducer';
import platformReducer from 'state/reducers/platformReducer';
import marketplaceReducer from 'state/reducers/marketplaceReduer';

type AsyncThunkConfig = {
  state?: unknown;
  extra?: unknown;
  rejectValue?: unknown;
  serializedErrorType?: unknown;
  pendingMeta?: unknown;
  fulfilledMeta?: unknown;
  rejectedMeta?: unknown;
};

export type GenericAsyncThunk = AsyncThunk<unknown, unknown, AsyncThunkConfig>;
export type PendingAction = ReturnType<GenericAsyncThunk['pending']>;
export type FulfilledAction = ReturnType<GenericAsyncThunk['fulfilled']>;
export type RejectedAction = ReturnType<GenericAsyncThunk['rejected']>;

const sessionPersistConfig = {
  key: 'session',
  storage: localForage,
  whitelist: ['authToken']
};

const combinedReducer = combineReducers({
  post: postReducer,
  merchant: merchantReducer,
  snackbar: snackbarReducer,
  user: userReducer,
  platform: platformReducer,
  marketplace: marketplaceReducer,
  session: persistReducer(sessionPersistConfig, sessionReducer)
});

export type RootState = ReturnType<typeof combinedReducer>;

const rootReducer: Reducer = (state: RootState, action: AnyAction) => {
  const lastUrl = window.location.pathname;
  if (lastUrl !== '/' && lastUrl !== '/login' && lastUrl !== '/auth-expired') {
    localStorage.setItem('lastUrl', lastUrl);
  }
  if (action.type === 'auth/logout') {
    state = {} as RootState;
  }

  return combinedReducer(state, action);
};

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false
    })
});

const persistor = persistStore(store);

export { store, persistor };
