import React, { useEffect, useState } from 'react';
import classnames from 'classnames/bind';
import moment, { Duration } from 'moment';

import styles from './Timer.module.scss';

const cx = classnames.bind(styles);

interface TimerProps {
  date: Date | string;
  type: number;
}

const Timer = (props: TimerProps) => {
  const [timer, setTimer] = useState<Duration>();

  useEffect(() => {
    setInterval(() => {
      setTimer(moment.duration(moment(props.date).diff(moment(), 'seconds'), 'seconds'));
    }, 1000);
  }, []);

  return (
    <>
      {timer && (
        <>
          {props.type === 1 ? (
            <div className={cx('flexbox', 'big')}>
              <div className={cx('group')}>
                <div className={cx('time')}>{timer?.days()}</div>
                <div className={cx('sub')}>Days</div>
              </div>
              <div className={cx('colon')}>:</div>
              <div className={cx('group')}>
                <div className={cx('time')}>{timer?.hours()}</div>
                <div className={cx('sub')}>Hours</div>
              </div>
              <div className={cx('colon')}>:</div>
              <div className={cx('group')}>
                <div className={cx('time')}>{timer?.minutes()}</div>
                <div className={cx('sub')}>Minutes</div>
              </div>
              <div className={cx('colon')}>:</div>
              <div className={cx('group')}>
                <div className={cx('time')}>{timer?.seconds()}</div>
                <div className={cx('sub')}>Seconds</div>
              </div>
            </div>
          ) : (
            <div className={cx('flexbox', 'small')}>
              <div className={cx('group')}>
                <div className={cx('time')}>
                  {timer?.days()}
                  <span className={cx('sub')}>d</span>
                </div>
              </div>
              <div className={cx('colon')}>:</div>
              <div className={cx('group')}>
                <div className={cx('time')}>
                  {timer?.hours()}
                  <span className={cx('sub')}>h</span>
                </div>
              </div>
              <div className={cx('colon')}>:</div>
              <div className={cx('group')}>
                <div className={cx('time')}>
                  {timer?.minutes()}
                  <span className={cx('sub')}>m</span>
                </div>
              </div>
              <div className={cx('colon')}>:</div>
              <div className={cx('group')}>
                <div className={cx('time')}>
                  {timer?.seconds()}
                  <span className={cx('sub')}>s</span>
                </div>
              </div>
            </div>
          )}
        </>
      )}
    </>
  );
};

export default Timer;
