import { httpClient } from 'http/httpClient';
import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import classnames from 'classnames/bind';
import Loading from 'components/Loading';
import DateTime from 'components/DateTime';
import ConfirmModal from 'components/ConfirmModal';
import { Post } from 'types';
import { getPrice, getNftUrl } from 'utils/post';
import { openSnackbar } from 'state/reducers/snackbarReducer';
import { PLATFORM } from 'types';
import SellModal from './SellModal';
import TransferModal from './TransferModal';
import styles from './Collections.module.scss';

const cx = classnames.bind(styles);

const Collections = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState<Post[]>([]);
  const [listingItem, setListingItem] = useState<Post | undefined>();
  const [transferItem, setTransferItem] = useState<Post | undefined>();
  const [cancelItem, setCancelItem] = useState<Post | undefined>();
  const [cancellingGuid, setCancellingGuid] = useState('');
  const handleCancel = async () => {
    if (cancelItem?.marketplaceListingGUID) {
      try {
        setCancelItem(undefined);
        setCancellingGuid(cancelItem.marketplaceListingGUID);
        await httpClient.post(`/marketplacelistings/${cancelItem.marketplaceListingGUID}/cancel`);
        await loadItems();
        dispatch(
          openSnackbar({
            message: 'Successfully cancelled',
            type: 'success'
          })
        );
      } catch (e) {
        dispatch(
          openSnackbar({
            message: e + '',
            type: 'danger'
          })
        );
      } finally {
        setCancellingGuid('');
      }
    } else {
      dispatch(
        openSnackbar({
          message: 'Something went wrong. Please try again.',
          type: 'danger'
        })
      );
    }
  };

  const loadItems = async () => {
    try {
      setLoading(true);
      const response = await httpClient.get(`/posts/search?isPurchasedByCallingUser=true&platform=${PLATFORM}`);
      setData(response.data);
    } catch (e) {
      dispatch(
        openSnackbar({
          message: e + '',
          type: 'danger'
        })
      );
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    loadItems();
  }, []);

  const handleTransfer = async (userGUID: string) => {
    setLoading(true);
    try {
      await httpClient.post(`/posts/${transferItem?.guid}/nft/transfer`, { toUserGUID: userGUID });
      dispatch(
        openSnackbar({
          message: 'Successfully transferred.',
          type: 'success'
        })
      );
      setTransferItem(undefined);
      await loadItems();
    } catch (e) {
      dispatch(
        openSnackbar({
          message: e + '',
          type: 'danger'
        })
      );
    } finally {
      setLoading(false);
    }
  };
  return (
    <>
      {loading && <Loading />}
      {!loading && data.length === 0 && (
        <div className={cx('no-collections')}>
          <p>
            You currently have no NFTs. Start collecting today by viewing some of our partner collections{' '}
            <Link to="/">here.</Link>
          </p>
        </div>
      )}

      {!loading &&
        data.map((item) => (
          <div className={cx('collection')} key={item.guid}>
            <img className={cx('collection-img')} src={item.normalizedContentUrl} alt="profile" />
            <div className={cx('collection-content')}>
              <p>Collection: {item.postGroupName}</p>
              <p>Title: {item.name}</p>
              <p>
                Collaborator:{' '}
                {item.lastSoldMarketplaceListingSellerUserDisplayName || item.marketplaceListingSellerUserDisplayName}
              </p>
              <p>
                Purchased: <DateTime date={item.dateCreated} />
              </p>
              <p>Amount: {getPrice(item)}</p>
              {getNftUrl(item) && (
                <p>
                  Link:{' '}
                  <a href={getNftUrl(item)} target="_blank">
                    {getNftUrl(item)}
                  </a>
                </p>
              )}
              <div className={cx('collection-content-bottom')}>
                {!item.marketplaceListingGUID && (
                  <button className="button is-success mr-2" onClick={() => setListingItem(item)}>
                    Sell
                  </button>
                )}
                {item.marketplaceListingGUID && (
                  <button
                    className={cx('button', 'is-warning', 'mr-2', {
                      'is-loading': cancellingGuid === item.marketplaceListingGUID
                    })}
                    onClick={() => setCancelItem(item)}>
                    Cancel Sale
                  </button>
                )}
                <button className="button is-danger" onClick={() => setTransferItem(item)}>
                  Transfer
                </button>
              </div>
            </div>
          </div>
        ))}
      {listingItem && (
        <SellModal
          reloadItems={loadItems}
          show={!!listingItem}
          purchasedItem={listingItem}
          onClose={() => setListingItem(undefined)}
        />
      )}
      {transferItem && <TransferModal handleTransfer={handleTransfer} onClose={() => setTransferItem(undefined)} />}
      {cancelItem && <ConfirmModal onConfirm={handleCancel} onClose={() => setCancelItem(undefined)} />}
    </>
  );
};

export default Collections;
