import React from 'react';
import classnames from 'classnames/bind';
import { TailSpin } from 'react-loading-icons';

import styles from './Loading.module.scss';

const cx = classnames.bind(styles);

const Loading = () => (
  <div className={cx('container')}>
    <TailSpin stroke="#304555" />
  </div>
);

export default Loading;
