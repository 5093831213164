export const ETH = 1223.99;
//price 6/13 7:31 AM PT

export const ethToUsd = (amount: number) => {
  return (amount * ETH).toFixed(2);
};

export const usdToEth = (amount: number) => {
  return (amount / ETH).toFixed(6);
};
