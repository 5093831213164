import React, { MouseEventHandler, ReactNode } from 'react';
import classnames from 'classnames/bind';

import styles from './Modal.module.scss';

const cx = classnames.bind(styles);

export type ModalProps = {
  children: ReactNode;
  onClose?: MouseEventHandler<HTMLDivElement>;
  size?: 'sm' | 'md' | 'lb' | 'auto' | 'full';
  backgroundColor?: 'black' | 'white';
  className?: string;
};

const Modal = (props: ModalProps) => {
  return (
    <div className={cx('modal', 'is-active', cx(props.className))}>
      <div className={cx('modal-background', { lightbox: props.size === 'lb' })} onClick={props.onClose} />
      {props.size === 'lb' ? (
        <div className={cx('modal-lightbox')}>{props.children}</div>
      ) : (
        <div
          className={cx(
            'modal-content',
            { 'modal-black': props.backgroundColor === 'black' },
            props.size && `size-${props.size}`
          )}>
          {props.onClose && (
            <div className={cx('close')} onClick={props.onClose}>
              <span className={cx('material-icons-outlined')}>close</span>
            </div>
          )}
          {props.children}
        </div>
      )}
    </div>
  );
};

export default Modal;
