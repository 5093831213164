import { createSlice } from '@reduxjs/toolkit';

interface SnackbarState {
  isShow: boolean;
  message: string;
  duration: number;
  type?: 'success' | 'danger' | 'info';
}

const initialState: SnackbarState = {
  isShow: false,
  duration: 4000,
  message: ''
};

const snackbarSlice = createSlice({
  name: 'snackbar',
  initialState,
  reducers: {
    openSnackbar: (state, action) => {
      state.isShow = true;
      state.message = action.payload.message;
      state.duration = action.payload.duration || initialState.duration;
      state.type = action.payload.type;
      return state;
    },
    closeSnackbar: (state) => {
      state.isShow = false;
      state.message = '';
      state.type = undefined;
      return state;
    }
  }
});

export const { openSnackbar, closeSnackbar } = snackbarSlice.actions;
export default snackbarSlice.reducer;
