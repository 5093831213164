import { httpClient } from 'http/httpClient';
import { AnyAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { LoginRequest, RegisterRequest } from 'types';

export const registerUser = createAsyncThunk('session/register', async (data: RegisterRequest) => {
  data.primaryPlatform = process.env.REACT_APP_PRIMARY_PLATFORM;
  const response = await httpClient.post('/users/register', data);
  return response.data;
});

export const loginUser = createAsyncThunk('session/login', async (data: LoginRequest) => {
  data.platform = process.env.REACT_APP_PRIMARY_PLATFORM;
  const response = await httpClient.post('/users/login/password', data);
  return response.data;
});

export interface SessionState {
  status: string;
  loginError?: string;
  registerError?: string;
  authToken?: string;
  isRegisterSuccess?: boolean;
  lastVisted?: string;
  cacheUser?: LoginRequest;
}

const initialState: SessionState = {
  status: 'idle'
};

const sessionSlice = createSlice({
  name: 'session',
  initialState,
  reducers: {
    logout: () => initialState,
    resetLoginError: (state) => {
      state.loginError = undefined;
    },
    resetCacheUser: (state) => {
      state.cacheUser = undefined;
    },
    resetAuthToken: (state) => {
      state.authToken = undefined;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(registerUser.fulfilled, (state) => {
        state.status = 'fulfilled';
        state.isRegisterSuccess = true;
      })
      .addCase(loginUser.fulfilled, (state, action) => {
        state.status = 'fulfilled';
        state.authToken = action.payload.token;
      })
      .addCase(registerUser.rejected, (state, action) => {
        state.status = 'rejected';
        state.registerError = action.error.message;
      })
      .addCase(loginUser.rejected, (state, action) => {
        state.status = 'rejected';
        state.loginError = action.error.message;
      })
      .addMatcher(
        (action): action is AnyAction => /session\/(.*)\/pending/.test(action.type),
        (state) => {
          state.status = 'pending';
        }
      );
  }
});

export const { logout, resetLoginError, resetCacheUser, resetAuthToken } = sessionSlice.actions;
export default sessionSlice.reducer;
