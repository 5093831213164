import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate, useParams } from 'react-router-dom';
import classnames from 'classnames/bind';
import moment from 'moment';

import { fetchMarketplaceListingBid } from 'state/reducers/marketplaceReduer';
import { fetchMerchantWithCollection } from 'state/reducers/merchantReducer';
import { createView, fetchCollectionById, fetchCollectionItem, fetchItem, resetItem } from 'state/reducers/postReducer';
import { getPaymentProfiles, setConnectingValue } from 'state/reducers/userReducer';
import { RootState } from 'state/store';

import Timer from 'components/Timer';
import { MarketplaceListingPriceType, Post } from 'types';
import { colorGenerator } from 'utils';
import { getPrice } from 'utils/post';

import defaultAvatar from 'assets/images/default-avatar.png';
import spinner from 'assets/images/at-anim-v2.gif';
import ItemActivity from './ItemActivity';
import PurchaseButton from './PurchaseButton';
import ItemCard from './ItemCard';
import styles from './Item.module.scss';

const cx = classnames.bind(styles);

const Item = () => {
  const { post, merchant, marketplace } = useSelector((state: RootState) => ({
    post: state.post,
    merchant: state.merchant,
    marketplace: state.marketplace
  }));

  const dispatch = useDispatch();
  const { guid, postGroupGuid } = useParams();
  const [isSaleEnded, setIsSaleEnded] = useState(false);

  const navigate = useNavigate();

  const viewPiece = (selectedPost: Post) => {
    if (post && post.item?.guid !== selectedPost.guid) {
      dispatch(resetItem());
      navigate(`/piece/${postGroupGuid}/${selectedPost.guid}`);
    }
  };

  const incrementViews = (post?: Post) => {
    const view = {
      merchantGUID: post?.merchantGUID,
      postGUID: post?.guid,
      dateViewed: moment().format('MM/DD/YY hh:mm:ss')
    };

    dispatch(createView(view));
  };

  const getBidPrice = () => {
    if (!marketplace.bid?.bidCounterUSDAmount) {
      return '$0';
    }

    if (marketplace.bid?.bidCounterUSDAmount) {
      return `$${marketplace.bid?.bidCounterUSDAmount}`;
    } else {
      return `$${marketplace.bid?.usdAmount}`;
    }
  };

  useEffect(() => {
    if (post.collection === undefined && postGroupGuid) {
      dispatch(fetchCollectionById(postGroupGuid));
    }
    if (guid) {
      dispatch(fetchItem(guid));
    }
  }, [guid, postGroupGuid]);

  useEffect(() => {
    dispatch(setConnectingValue(false));
    if (post.item) {
      dispatch(fetchMerchantWithCollection(post.item.merchantGUID));
      if (post.item.marketplaceListingPriceType === MarketplaceListingPriceType.BestOffer) {
        dispatch(fetchMarketplaceListingBid(post.item?.marketplaceListingGUID || ''));
        setIsSaleEnded(moment(new Date()).isAfter(post.item.marketplaceListingExpirationDate));
      }
    }
    incrementViews(post.item);
  }, [post.item]);

  useEffect(() => {
    if (post.collection?.items === undefined && postGroupGuid) {
      dispatch(fetchCollectionItem(postGroupGuid));
    }
  }, [post.collection]);

  useEffect(() => {
    dispatch(getPaymentProfiles());
  }, []);

  return (
    <>
      {post.item ? (
        <>
          <div className={cx('container', 'piece')}>
            <div className={cx('columns')}>
              <div className={cx('column')}>
                <div>
                  <div className={cx('collaborator', 'is-size-7', 'has-text-muted')}>COLLECTION:</div>
                  <div className={cx('flexbox', 'mb-4')}>
                    <div className={cx('collaborator', 'is-size-4', 'has-text-weight-bold', 'mr-5')}>
                      {post.collection?.name}
                    </div>
                    <Link
                      to={`/collaborator/${merchant.merchant?.guid}`}
                      className={cx('is-link-neutral', 'flexbox', 'ml-auto')}>
                      By:
                      <object
                        className={cx('avatar', 'collaborator-avatar', 'mx-1')}
                        data={post.item?.marketplaceListingSellerUserProfileImageUrl || defaultAvatar}
                        style={{
                          backgroundColor: colorGenerator(post.item?.marketplaceListingSellerUserDisplayName || '')
                        }}
                      />
                      <span>{post.item?.marketplaceListingSellerUserDisplayName}</span>
                    </Link>
                  </div>
                </div>
                <div className={cx('serif', 'is-size-2', 'has-text-centered', 'has-text-weight-bold', 'mb-4', 'mt-6')}>
                  {post.item.name}
                </div>
                <ItemCard item={post.item} />
                <div className={cx('flexbox', 'justify-between', 'px-4', 'mt-2')}>
                  <div>Blockchain</div>
                  <div>{post.nftInformation?.blockchainType || 'Ethereum'}</div>
                </div>
              </div>
              <div className={cx('column')}>
                <div className={cx('flexbox', 'height-full')}>
                  <div className={cx('caustic', 'has-text-centered', 'pricing-padding')}>
                    {!post.item.marketplaceListingGUID && (
                      <div className={cx('no-sale', 'mt-4')}>This item has been already sold.</div>
                    )}
                    {post.item.marketplaceListingGUID && (
                      <>
                        <div className={cx('pricing', 'mt-4')}>
                          <div className={cx('flex')}>
                            <div className={cx('has-text-muted')}>
                              {post.item.marketplaceListingPriceType === MarketplaceListingPriceType.BestOffer
                                ? marketplace.bid?.bidCounterUSDAmount
                                  ? 'Current bid'
                                  : 'Starting bid'
                                : 'Purchase price'}
                            </div>
                            <div className={cx('has-text-weight-bold', 'is-size-2')}>
                              {post.item.marketplaceListingPriceType === MarketplaceListingPriceType.BestOffer
                                ? getBidPrice()
                                : getPrice(post.item)}
                            </div>
                          </div>
                          {post.viewMarketPlaceItem?.priceType === 'BestOffer' && (
                            <div className={cx('flex', 'ml-4')}>
                              <div className={cx('has-text-muted')}>Bid ends</div>
                              <div className={cx('flexbox')}>
                                <div className={cx('mx-auto')}>
                                  <Timer type={2} date={post.viewMarketPlaceItem.expirationDate as Date} />
                                </div>
                              </div>
                              <div className={cx('has-text-weight-semibold', 'has-text-muted')}>
                                {moment(post.viewMarketPlaceItem.expirationDate).format('LL, hA')}
                              </div>
                            </div>
                          )}
                        </div>
                        {(post.item.marketplaceListingPriceType === 'BestOffer' && !isSaleEnded) ||
                        post.item.marketplaceListingPriceType !== 'BestOffer' ? (
                            <PurchaseButton />
                          ) : (
                            <div className={cx('mt-2', 'has-text-weight-bold')}>Bidding has ended</div>
                          )}
                      </>
                    )}
                    <div className={cx('has-text-justified')}>
                      {post.item.description || post.item.descriptionShort}
                    </div>

                    {post.item.marketplaceListingPriceType === 'BestOffer' &&
                      !isSaleEnded &&
                      post.item.marketplaceListingExpirationDate !== undefined && (
                      <div className={cx('mt-4')}>
                        <div>Sale ends {moment(post.item.marketplaceListingExpirationDate).format('LLL')}</div>
                        <div className={cx('flexbox', 'justify-center')}>
                          <Timer date={post.item.marketplaceListingExpirationDate} type={1} />
                        </div>
                      </div>
                    )}

                    <div className={cx('section', 'has-text-left', 'mt-4')}>
                      <div className={cx('has-text-weight-semibold', 'has-text-muted', 'is-size-6', 'mb-2')}>
                        Item Activity
                      </div>
                      <ItemActivity />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className={cx('columns')}>
              <div className={cx('column')}>
                {(post.item.backstoryVideoUrl || post.item.backstoryDescription !== '') && (
                  <div className={cx('section')}>
                    <div className={cx('has-text-weight-semibold', 'has-text-muted', 'is-size-6')}>About the Item</div>

                    {post.item.backstoryVideoUrl && (
                      <div className={cx('collection-card')}>
                        <div>
                          <video
                            className={cx('player', 'mb-2')}
                            src={post.item.backstoryVideoUrl}
                            controls
                            controlsList="nodownload"
                          />
                          <div>{post.item.backstoryDescription}</div>
                        </div>
                      </div>
                    )}
                  </div>
                )}
              </div>
              <div className={cx('column')}>
                {post.item.nftBenefits && post.item.nftBenefits.length > 0 && (
                  <div className={cx('section')}>
                    <div className={cx('header')}>Benefits</div>
                    <div className={cx('content')}>
                      <ul className={cx('classic-list', 'check')}>
                        {post.item.nftBenefits?.map((benefit) => (
                          <li>{benefit}</li>
                        ))}
                      </ul>
                      <p className={cx('is-size-7')}>
                        Legal notice: While the creator makes every effort to fulfill these benefits, there is no
                        guarantee that they will complete it and you should NOT make a purchase based on the benefits
                        alone.
                      </p>
                      <div>
                        <img className={cx('signature')} src={merchant.merchant?.signatureImageUrl} />
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className={cx('container mt-6')}>
              <div className={cx('section')}>
                <div className={cx('has-text-weight-semibold', 'has-text-muted', 'is-size-6')}>
                  More from the Collection
                </div>

                <div className={cx('trance-wrapper')}>
                  <div className={cx('trance', 'small')}>
                    {post.collection?.items &&
                      post.collection.items
                        // .filter((i, index) => index < 6)
                        .map(
                          (post: Post) =>
                            (post.marketplaceListingETHAmount || post.marketplaceListingUSDAmount) && (
                              <div key={post.guid} className={cx('trance-card')} onClick={() => viewPiece(post)}>
                                <div className={cx('top')}>
                                  {post.postType === 'Image' ? (
                                    <img className={cx('thumbnail')} src={post.contentUrl || post.thumbnailImageUrl} />
                                  ) : (
                                    <img className={cx('thumbnail')} src={post.thumbnailImageUrl} />
                                  )}
                                </div>
                              </div>
                            )
                        )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <div className={cx('container mt-6')}>
            <div className={cx('section')}>
              <div className={cx('has-text-weight-semibold', 'has-text-muted', 'is-size-6')}>
                More from the Collection
              </div>

              <div className={cx('trance-wrapper')}>
                <div className={cx('trance', 'small')}>
                  {post.collection?.items &&
                    post.collection.items
                      .filter((i, index) => index < 6)
                      .map(
                        (post: Post) =>
                          post.marketListing && (
                            <div key={post.guid} className={cx('trance-card')} onClick={() => viewPiece(post)}>
                              <div className={cx('top')}>
                                {post.postType === 'Image' ? (
                                  <img className={cx('thumbnail')} src={post.contentUrl || post.thumbnailImageUrl} />
                                ) : (
                                  <img className={cx('thumbnail')} src={post.thumbnailImageUrl} />
                                )}
                              </div>
                            </div>
                          )
                      )}
                </div>
              </div>
            </div>
          </div> */}
        </>
      ) : (
        <div className={cx('full-spinner')}>
          <img src={spinner} />
        </div>
      )}
    </>
  );
};

export default Item;
