import React from 'react';
import classnames from 'classnames/bind';
import clarence from 'assets/images/clarence.png';
import jimmy from 'assets/images/jimmy.png';

import styles from 'routes/About/About.module.scss';

const cx = classnames.bind(styles);

const About = () => {
  return (
    <div className={cx('air')}>
      <div className={cx('header')}>
        <div className={cx('container', 'ocean')}>
          <div>
            <div className={cx('is-size-2', 'has-text-weight-bold', 'has-text-centered')}>
              Welcome to Avant & Thomas
            </div>
            <div className={cx('description', 'mt-4')}>
              We're a creative NFT studio and marketplace working with some of the biggest names in the entertainment
              and sports industry. Our vision is simple: To create original and authentic digital masterpieces that
              bring fans, collectors, and personalities closer together.
            </div>
          </div>
        </div>
      </div>

      <div className={cx('container', 'ocean')}>
        <div className={cx('section', 'founders')}>
          <div>
            <div className={cx('flexbox', 'wrap', 'align-start')}>
              <div className={cx('about')}>
                <img src={clarence} />
                <div className={cx('serif', 'is-size-4', 'mt-4', 'mb-2', 'has-text-centered')}>Alex Avant</div>
                <div className={cx('justify')}>
                  <p>
                    Alexander Devore Avant is a Producer, Actor, and Writer. In 2012, he appeared in the movie
                    Gallowwalkers and played the character of Forty Bold, then he played the role of Ben in the 2006 TV
                    series, Courting Alex. In 2005, he appeared in Shadowboxer and Charmed (TV Series) as a Car Driver.
                    In 2011, he produced Beverly Hills Fabulous (TV Series) and then produced Gallowwalkers, The Perfect
                    Match, Can’t Stop, Won’t Stop: A Bad Boy Story, and Dad Stop Embarrassing Me!. Furthermore, as a
                    writer, he has one credit – Beverly Hills Fabulous (TV Series).
                  </p>
                </div>
              </div>
              <div className={cx('about')}>
                <img src={jimmy} />
                <div className={cx('serif', 'is-size-4', 'mt-4', 'mb-2', 'has-text-centered')}>Jimmy Thomas</div>
                <div className={cx('justify')}>
                  <p>
                    Mr. Thomas is a well known entertainment executive who has written and produced music for several
                    major and independent artists, record companies, movies and commercials. He has earned several
                    multi-platinum and gold records over his career. Mr. Thomas is currently head of business
                    development as well as a Executive producer for Lion Forge Animation Studio. He was part of the
                    Oscar winning team that produced “Hair Love” The winner of the Best Animated Short for 2020.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className={cx('flexbox')}>
          <div className={cx('invitation')}>
            <div className={cx('has-text-centered')}>
              <div className={cx('is-size-5', 'has-text-weight-bold', 'mb-4')}>Invitation</div>
              <div className={cx('serif', 'is-size-3', 'mt-4')}>Become a Founding Member</div>
              <div>Welcome to the opportunity to register for the very first Avant & Thomas NFT</div>
            </div>
            <div className={cx('mt-6')}>
              <p>
                Avant & Thomas is currently offering exclusive NFTs to people interested in supporting our vision and
                becoming part of the Avant & Thomas Founders Club (ATFC). As an ATFC member, you will get early access
                to NFT drops on Avant & Thomas as well as the option to attend exclusive events and experiences planned
                for ATFC members and select groups.
              </p>
              <p>
                These NFTs will be unique and available only to the first 1,000 founding members. Each ATFC NFT sells
                for a fixed price of 1.698 ETH.
              </p>
            </div>
            <p>
              As an Avant & Thomas founding member, you get the following benefits and privileges:
              <ol>
                <li>Early access to exclusive NFT drops from any of our collaborators.</li>
                <li>Invitations to exclusive events and experiences.</li>
                <li>Opportunities to meet with some of the Avant & Thomas collaborators.</li>
                <li>Chance to network with some of the biggest names in the sports and entertainment industry.</li>
              </ol>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
