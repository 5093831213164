import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import classnames from 'classnames/bind';

import styles from 'routes/Collaborator/Collaborator.module.scss';
import CollectionCard from 'components/CollectionCard';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'state/store';
import { fetchMerchant } from 'state/reducers/merchantReducer';
import { fetchMerchantCollections, setCollection } from 'state/reducers/postReducer';
import defaultAvatar from 'assets/images/default-avatar.png';

import Skeleton from 'components/Skeleton';

const cx = classnames.bind(styles);

const Collaborator = () => {
  const { merchant, post } = useSelector((state: RootState) => ({
    merchant: state.merchant,
    post: state.post
  }));
  const dispatch = useDispatch();
  const { guid } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    if ((merchant.merchant === undefined || (merchant.merchant && merchant.merchant.userGUID !== guid)) && guid) {
      dispatch(fetchMerchant(guid));
      dispatch(fetchMerchantCollections(guid));
    }
  }, []);

  return (
    <>
      <div className={cx('header')}>
        <div className={cx('container', 'flexbox', 'accord')}>
          <div className={cx('flex', 'name')}>
            <div className={cx('has-text-centered')}>
              <div>
                <img className={cx('avatar')} src={merchant.merchant?.profileImageUrl || defaultAvatar} />
              </div>
              <div className={cx('collaborator', 'is-size-3', 'has-text-weight-bold', 'mt-2')}>
                {merchant.merchant?.name ? merchant.merchant?.name : merchant.merchant?.userDisplayName}
              </div>
            </div>
            <div className={cx('description', 'mt-4')}>{merchant.merchant?.description}</div>
            {merchant.merchant?.introVideoUrl && (
              <>
                <div className={cx('flexbox', 'video', 'mt-4')}>
                  <div className={cx('video-intro-container')}>
                    <video
                      className={cx('player', 'intro')}
                      src={merchant.merchant?.introVideoUrl}
                      controls
                      controlsList="nodownload"
                      loop
                    />
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>

      <div className={cx('container', 'mt-6')}>
        {merchant.merchant && (
          <div className={cx('is-size-5', 'has-text-weight-bold')}>
            {merchant.merchant?.name ? merchant.merchant?.name : merchant.merchant.userDisplayName}'s Collections
          </div>
        )}

        <div className={cx('trance-wrapper')}>
          <div className={cx('trance')}>
            {post.merchantCollections ? (
              post.merchantCollections.length > 0 ? (
                post.merchantCollections.map((collection) => (
                  <div
                    className={cx('trance-card', 'clear')}
                    onClick={() => {
                      dispatch(setCollection(collection));
                      navigate(`/collection/${collection.guid}`);
                    }}>
                    <CollectionCard
                      type={2}
                      collection={collection}
                      collectionBanner={collection.thumbnailImageUrl || ''}
                      pieces={collection.activeMarketplaceListingCount || 0}
                      // sales={0}
                      views={
                        collection.nftCollectionPostGroupViewCount !== undefined
                          ? collection.nftCollectionPostGroupViewCount.toString()
                          : '0'
                      }
                    />
                  </div>
                ))
              ) : (
                <div className={cx('coming-soon')}>Creator NFT coming soon</div>
              )
            ) : (
              <>
                <Skeleton className={cx('collection-skeleton')} type="rect" height="356px" width="calc(25% - 1rem)" />
                <Skeleton className={cx('collection-skeleton')} type="rect" height="356px" width="calc(25% - 1rem)" />
                <Skeleton className={cx('collection-skeleton')} type="rect" height="356px" width="calc(25% - 1rem)" />
                <Skeleton className={cx('collection-skeleton')} type="rect" height="356px" width="calc(25% - 1rem)" />
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Collaborator;
