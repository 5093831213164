import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import classnames from 'classnames/bind';

import { fetchCollections, fetchFeaturedCollection, setCollection } from 'state/reducers/postReducer';
import { RootState } from 'state/store';

import CollectionCard from 'components/CollectionCard';
import Skeleton from 'components/Skeleton';

import { fetchPlatform } from 'state/reducers/platformReducer';
import { fetchFeaturedMerchants } from 'state/reducers/merchantReducer';
import FeaturedSection from 'components/FeaturedSection';
import styles from './Home.module.scss';

const cx = classnames.bind(styles);

function Home() {
  const { post, platform } = useSelector((state: RootState) => ({
    post: state.post,
    platform: state.platform
  }));
  const dispatch = useDispatch();

  const navigate = useNavigate();

  useEffect(() => {
    dispatch(fetchPlatform());
    dispatch(fetchCollections());
  }, []);

  useEffect(() => {
    if (platform.platform?.featuredSection && platform.platform.featuredSection === 'Merchant') {
      dispatch(fetchFeaturedMerchants());
    } else {
      dispatch(fetchFeaturedCollection());
    }
  }, [platform.platform?.featuredSection]);

  return (
    <>
      <div className={cx('container', 'pt-4')}>
        <FeaturedSection />
        <div className={cx('callout', 'mt-4', 'mb-6')}>
          <div className={cx('highlight')}>BECOME AN AVANT & THOMAS FOUNDING MEMBER</div>
          <div className={cx('mx-4')}>
            <div>
              Access to exclusive events, celebrity meet & greets, exclusive drops, and a unique Avant & Thomas founding
              member NFT.
            </div>
            <div className={cx('mt-1', 'is-size-7')}>Learn more</div>
          </div>
        </div>

        <div className={cx('is-size-4', 'has-text-weight-semibold', 'has-text-centered', 'mb-4')}>OUR COLLECTIONS</div>
        <div className={cx('trance-wrapper')}>
          <div className={cx('trance')}>
            {post.collections ? (
              post.collections.map((collection) => (
                <div
                  key={collection.guid}
                  className={cx('trance-card', 'clear')}
                  onClick={() => {
                    dispatch(setCollection(collection));
                    navigate(`/collection/${collection.guid}`);
                  }}>
                  <CollectionCard
                    type={1}
                    collection={collection}
                    collectionBanner={collection.thumbnailImageUrl}
                    pieces={collection.pieces || 0}
                  />
                </div>
              ))
            ) : (
              <>
                <Skeleton className={cx('collection-skeleton')} type="rect" height="422px" width="calc(25% - 1rem)" />
                <Skeleton className={cx('collection-skeleton')} type="rect" height="422px" width="calc(25% - 1rem)" />
                <Skeleton className={cx('collection-skeleton')} type="rect" height="422px" width="calc(25% - 1rem)" />
                <Skeleton className={cx('collection-skeleton')} type="rect" height="422px" width="calc(25% - 1rem)" />
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default Home;
