import React from 'react';
import Modal from 'components/Modal';
import classnames from 'classnames/bind';
import styles from './ConfirmModal.module.scss';

const cx = classnames.bind(styles);

export type ConfirmModalProps = {
  message?: string;
  confirmLabel?: string;
  cancelLabel?: string;
  onClose: () => void;
  onConfirm: () => void;
};
const ConfirmModal = ({
  onClose,
  onConfirm,
  message = 'Are you sure?',
  confirmLabel = 'Confirm',
  cancelLabel = 'Cancel'
}: ConfirmModalProps) => {
  return (
    <Modal onClose={onClose}>
      <div className={cx('message')}>{message}</div>
      <div className={cx('mt-4', 'actions')}>
        <button className="button is-success" onClick={onConfirm}>
          {confirmLabel}
        </button>
        <button className="button is-danger ml-4" onClick={onClose}>
          {cancelLabel}
        </button>
      </div>
    </Modal>
  );
};

export default ConfirmModal;
