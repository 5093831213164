import moment from 'moment';
import React from 'react';

export interface DateTimeProps {
  date: string | Date;
}

const DateTime = ({ date }: DateTimeProps) => {
  const dt = moment(date);
  return <span title={dt.format('LT')}>{dt.format('L')}</span>;
};

export default DateTime;
