import { PurchasedMarketplaceListing, MarketlistingNFT } from 'types';

export const getPrice = (item: PurchasedMarketplaceListing | MarketlistingNFT): string => {
  if (item.ethAmount) {
    return `${item.ethAmount} ETH`;
  }

  if (item.usdAmount) {
    return `$${item.usdAmount}`;
  }

  return '';
};
