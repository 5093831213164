import React from 'react';
import classnames from 'classnames/bind';

import styles from './Contact.module.scss';

const cx = classnames.bind(styles);

const Contact = () => {
  return (
    <div className={cx('container', 'ocean', 'pt-6')}>
      <div className={cx('has-text-weight-bold', 'has-text-primary', 'is-size-3')}>Contact</div>
      <div className={cx('mt-4')}>
        At Avant & Thomas, our goal is to curate exclusive NFT collectibles featuring some of the biggest names in
        sports and entertainment. If you'd like to learn more or feature your own content on this site, please send us
        an email below.
      </div>
      <div className={cx('has-text-weight-bold', 'has-text-primary', 'is-size-5', 'mt-5')}>NFTs</div>
      <div className={cx('mt-4')}>
        Use this email if you would like to sell NFTs on Avant & Thomas or have questions about the NFTs we have for
        sale.
      </div>
      <div className={cx('mt-4')}>
        <a href="mailto:support@avantthomas.com" className={cx('has-text-weight-bold')}>
          nfts@avantthomas.com
        </a>
      </div>
      <div className={cx('has-text-weight-bold', 'has-text-primary', 'is-size-5', 'mt-5')}>Media</div>
      <div className={cx('mt-4')}>
        Reach out if you are from the media and would like to learn more about Avant & Thomas.
      </div>
      <div className={cx('mt-4')}>
        <a href="mailto:media@avantthomas.com" className={cx('has-text-weight-bold')}>
          media@avantthomas.com
        </a>
      </div>
      <div className={cx('has-text-weight-bold', 'has-text-primary', 'is-size-5', 'mt-5')}>Legal</div>
      <div className={cx('mt-4')}>
        We want to hear if you suspect or have concerns over privacy, copyright, or offensive content.
      </div>
      <div className={cx('mt-4')}>
        <a href="mailto:legal@avantthomas.com" className={cx('has-text-weight-bold')}>
          legal@avantthomas.com
        </a>
      </div>
      <div className={cx('has-text-weight-bold', 'has-text-primary', 'is-size-5', 'mt-5')}>Last Resort</div>
      <div className={cx('mt-4')}>If all else fails, contact me directly.</div>
      <div className={cx('mt-4')}>
        <div>Dan Baxter</div>
        <div>
          <a href="mailto:max@avantthomas.com" className={cx('has-text-weight-bold')}>
            dan@avantthomas.com
          </a>
        </div>
        <div className={cx('mt-4')}>Office Address:</div>
        <div>400 Spectrum Center Dr</div>
        <div>Suite #2180</div>
        <div>Irvine CA 92618</div>
      </div>
    </div>
  );
};

export default Contact;
