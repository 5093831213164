import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link, Outlet, useLocation, useNavigate } from 'react-router-dom';
import classnames from 'classnames/bind';

import { logout } from 'state/reducers/sessionReducer';

import styles from './AccountBase.module.scss';

const cx = classnames.bind(styles);

const AccountBase = () => {
  const [mobileState, setMobileState] = useState(1);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const signOut = () => {
    dispatch(logout());
    navigate('/');
  };
  const location = useLocation();

  const activeTab = location.pathname.replace('/account', '').replace('/', '');

  return (
    <div>
      <div className={cx('container')}>
        <div className={cx('flexbox', 'align-start', 'pad', `mobile-view-${mobileState}`)}>
          <div className={cx('settings')}>
            {/* <div className={cx('header')}>Account Management</div> */}
            <ul className={cx('settings-list')} onClick={() => setMobileState(2)}>
              <li>
                <Link to="profile">
                  <div className={cx('item', { active: !activeTab || activeTab === 'profile' })}>Profile</div>
                </Link>
              </li>
              <li>
                <Link to="security">
                  <div className={cx('item', { active: activeTab === 'security' })}>Security</div>
                </Link>
              </li>
              <li>
                <Link to="wallets">
                  <div className={cx('item', { active: activeTab === 'wallets' })}>My Wallets</div>
                </Link>
              </li>
              <li>
                <Link to="credit-cards">
                  <div className={cx('item', { active: activeTab === 'credit-cards' })}>Credit Cards</div>
                </Link>
              </li>
              <li>
                <Link to="history">
                  <div className={cx('item', { active: activeTab === 'history' })}>History</div>
                </Link>
              </li>
              <li>
                <Link to="collections">
                  <div className={cx('item', { active: activeTab === 'collections' })}>Collections</div>
                </Link>
              </li>
              <li onClick={signOut}>
                <div className={cx('item', 'danger')}>Sign Out</div>
              </li>
            </ul>
          </div>
          <div className={cx('pandora')}>
            <button
              className={cx('back', 'button', 'is-outlined', 'is-small', 'mb-4')}
              onClick={() => setMobileState(1)}>
              Back to menu
            </button>
            <Outlet />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AccountBase;
