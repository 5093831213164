import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import classnames from 'classnames/bind';

import { openSnackbar } from 'state/reducers/snackbarReducer';
import { changePassword } from 'state/reducers/userReducer';
import { RootState } from 'state/store';

import { PasswordChangeRequest } from 'types';

import styles from './Security.module.scss';

const cx = classnames.bind(styles);

const Security = () => {
  const { user } = useSelector((state: RootState) => ({
    user: state.user
  }));
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    reset
  } = useForm<PasswordChangeRequest>();
  const dispatch = useDispatch();

  const notifySave = () => {
    dispatch(
      openSnackbar({
        message: 'Changes saved.',
        type: 'success'
      })
    );
    reset();
  };

  const onSubmit = (data: PasswordChangeRequest) => {
    dispatch(changePassword(data));
  };

  useEffect(() => {
    if (user.isChangePasswordSuccess) {
      notifySave();
    }
  }, [user.isChangePasswordSuccess]);

  useEffect(() => {
    if (user.status === 'error') {
      dispatch(
        openSnackbar({
          message: user.error,
          type: 'danger'
        })
      );
    }
  }, [user.status]);

  return (
    <>
      <div className={cx('has-text-weight-bold', 'is-size-4', 'mb-4')}>Security</div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className={cx('columns')}>
          <div className={cx('column')}>
            <div className={cx('field')}>
              <label className={cx('label')}>Current Password:</label>
              <div className={cx('control')}>
                <input
                  type="password"
                  className={cx('input')}
                  {...register('oldPassword', {
                    required: true
                  })}
                />
              </div>
            </div>
            <div className={cx('field')}>
              <label className={cx('label')}>New Password:</label>
              <div className={cx('control')}>
                <input
                  type="password"
                  className={cx('input')}
                  {...register('newPassword', {
                    required: true,
                    pattern: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/
                  })}
                />
              </div>
              {errors.newPassword && (
                <div className={cx('is-size-7', 'has-text-danger', 'mt-1')}>
                  Password must be at least 8 characters and contain at least 1 uppercase and lowercase letter, and 1
                  number.
                </div>
              )}
            </div>
            <div className={cx('field')}>
              <label className={cx('label')}>Confirm New Password:</label>
              <div className={cx('control')}>
                <input
                  type="password"
                  className={cx('input')}
                  {...register('confirmNewPassword', {
                    required: true,
                    validate: (value) => value === watch('newPassword')
                  })}
                />
              </div>
              {errors.confirmNewPassword && (
                <div className={cx('is-size-7', 'has-text-danger', 'mt-1')}>Passwords do not match.</div>
              )}
            </div>

            <div className={cx('flexbox', 'ft-mt-4')}>
              <button type="submit" className={cx('button')}>
                Save changes
              </button>
            </div>
          </div>
          <div className={cx('column')} />
        </div>
      </form>
    </>
  );
};

export default Security;
